import {
  call,
  put,
  select,
  takeLatest,
  StrictEffect,
} from 'redux-saga/effects';
import groupBy from 'lodash/groupBy';

import {
  getIntegrationRunLogs,
  getLatestIntegrationRun,
  IntegrationConfig,
  IntegrationRun,
  IntegrationRunLog,
} from '@m3ter-com/m3ter-api';

import { IntegrationRunDetailsData } from '@/types/data';

import { extractError } from '@/util/error';
import {
  serialiseTaskEntries,
  groupTaskDefinitions,
} from '@/util/integrations';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  loadIntegrationRun,
  loadIntegrationRunFailure,
  loadIntegrationRunSuccess,
  LoadIntegrationRunAction,
} from './integrationRuns';

export function* loadIntegrationRunSaga(
  action: LoadIntegrationRunAction
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);
  let integrationRunConfig: IntegrationConfig | undefined;

  const { entityId, entityType } = action.payload;

  try {
    const integrationRun: IntegrationRunLog = yield call(
      getLatestIntegrationRun,
      organizationId,
      entityType,
      entityId
    );

    const { payload: integrationRunLogString } = yield call(
      getIntegrationRunLogs,
      organizationId,
      integrationRun.id
    );

    const integrationRunLog: IntegrationRun = JSON.parse(
      atob(integrationRunLogString)
    );

    integrationRunConfig = integrationRunLog.configuration;

    const groupedTaskDefinitions = groupTaskDefinitions(
      integrationRunLog.taskDefinitions
    );

    const integrationDetailsList = groupedTaskDefinitions.map((taskGroup) => {
      // In the event we only have 1 task definition, we still need to treat the parent as a child.
      // This is because it is supposed to act as a wrapper object for the children tasks
      // however in all other cases we need to remove the parent from being mapped as if it
      // has children it will never have action ran against it.

      const childrenDependantTaskGroups =
        taskGroup.length > 1 ? taskGroup.slice(1) : taskGroup;

      // As we don't have the entities related to taskDefinitions from the API, we need to collate them and serialise them
      // ourselves, using the taskDefinition's taskId we can search through the other entities to find ones relevant to
      // this taskDefinition
      const mappedTaskGroups = childrenDependantTaskGroups
        .map<IntegrationRunDetailsData | undefined>((taskDefinition) => {
          const { taskId } = taskDefinition;

          const { serialisedTaskEntries, aggregatedTime } =
            serialiseTaskEntries(integrationRunLog.entries, taskId);

          const taskRoute = integrationRunLog.taskRoute.filter(
            (route) => route.taskId === taskId
          );

          if (taskRoute.length === 0 && serialisedTaskEntries.length === 0) {
            return undefined;
          }

          return {
            log: integrationRun,
            taskDefinition,
            taskEntries: serialisedTaskEntries,
            taskRoute,
            totalStageTimeTaken: aggregatedTime,
          };
        })
        .filter(Boolean) as Array<IntegrationRunDetailsData>;

      return {
        parentTaskId: taskGroup[0].taskId,
        parentTaskName: taskGroup[0].taskName,
        mappedTaskGroups,
      };
    });

    const groupTasksByName = groupBy(
      integrationDetailsList.flatMap((details) => details.mappedTaskGroups),
      'taskDefinition.taskName'
    );

    const perTaskAverageTimeTaken = Object.keys(groupTasksByName).map(
      (taskKey) => {
        const average =
          groupTasksByName[taskKey].reduce(
            (taskA, taskB) => taskA + taskB.totalStageTimeTaken,
            0
          ) / groupTasksByName[taskKey].length;

        return { taskName: taskKey, average };
      }
    );

    yield put(
      loadIntegrationRunSuccess(
        integrationDetailsList,
        perTaskAverageTimeTaken,
        integrationRunConfig
      )
    );
  } catch (error) {
    yield put(loadIntegrationRunFailure(extractError(error)));
  }
}

export default function* integrationRunsSaga() {
  yield takeLatest(loadIntegrationRun.type, loadIntegrationRunSaga);
}
