import { AnalyticsJobType } from '@m3ter-com/console-core/types';

import { ReportType } from '@/types/data';

import { name as prepaymentsStatusReportRouteName } from './accounts/reports/prepaymentsStatus';
import { name as totalContractValueReportRouteName } from './accounts/reports/totalContractValue';
import { name as billingBasedRecurringRevenueReportRouteName } from './billing/reports/billingBasedRecurringRevenue';
import { name as monthOnMonthReportRouteName } from './billing/reports/monthOnMonth';
import { name as recurringRevenueReportRouteName } from './billing/reports/recurringRevenue';

const reportTypeRouteNameMap: Record<ReportType, string> = {
  [AnalyticsJobType.PrepaymentsStatusReport]: prepaymentsStatusReportRouteName,
  [AnalyticsJobType.TotalContractValueReport]:
    totalContractValueReportRouteName,
  [AnalyticsJobType.BillingBasedRecurringRevenueReport]:
    billingBasedRecurringRevenueReportRouteName,
  [AnalyticsJobType.MonthOnMonthReport]: monthOnMonthReportRouteName,
  [AnalyticsJobType.RecurringRevenueReport]: recurringRevenueReportRouteName,
};

export const getReportRouteName = (reportType: ReportType): string =>
  reportTypeRouteNameMap[reportType] ?? '';
