import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['item', 'command', 'list', 'button', 'groupTitle', 'divider'];

const menu: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    button: {
      padding: '5px 15px',
      borderWidth: '2px',
      borderRadius: '10px',
    },
    item: {
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
};

export default menu;
