import React, { ReactNode } from 'react';

import { Box, Spacer, useStyleConfig } from '@chakra-ui/react';

export interface HeaderProps {
  logo?: ReactNode;
  navigation?: ReactNode;
  actions?: ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  logo,
  navigation,
  actions,
}) => {
  const styles = useStyleConfig('Header');

  return (
    <Box __css={styles}>
      {logo}
      {navigation}
      <Spacer />
      {actions}
    </Box>
  );
};
