import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const tagButton: ComponentSingleStyleConfig = {
  baseStyle: (props) => {
    const { colorScheme: c, onClick } = props;

    return {
      _hover: {
        bg: onClick ? mode(`${c}.200`, `${c}.800`)(props) : undefined,
      },
    };
  },
  // Match to Button sizes.
  sizes: {
    lg: {
      height: 12,
      px: 6,
      fontSize: 'lg',
    },
    md: {
      height: 10,
      px: 4,
      fontSize: 'md',
    },
    sm: {
      height: 8,
      px: 3,
      fontSize: 'sm',
    },
    xs: {
      height: 6,
      px: 2,
      fontSize: 'xs',
    },
  },
};

export default tagButton;
