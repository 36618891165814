import {
  AggregationType,
  AlertSeverity,
  AlertStatus,
  AlertType,
  ApplyAsPricingType,
  AutoApprovePeriodUnit,
  DrawdownChargeTypes,
  BillLineItemType,
  BillJobFrequency,
  BillJobStatus,
  BillJobType,
  BillStatus,
  ChildBillingMode,
  Frequency,
  MeterFieldCategory,
  BalanceTransactionEntityType,
  PermissionEffect,
  StatementAggregationFrequency,
  StatementJobStatus,
  StatementAutoGenerateMode,
  CreditType,
} from '../enums';

import {
  AuditedEntity,
  CurrencyCode,
  DateString,
  DateTimeISOString,
  Entity,
  Id,
  PickListEntity,
} from './common';

type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;

/**
 * Typings for entities returned from APIs and those that
 * we construct on the frontend.
 */

export interface Account extends AuditedEntity {
  code: string;
  emailAddress: string;
  name: string;
  address?: Address;
  autoGenerateStatementMode?: StatementAutoGenerateMode;
  billEpoch?: DateString;
  currency?: CurrencyCode;
  customFields?: CustomFields;
  parentAccountId?: string;
  purchaseOrderNumber?: string;
  statementDefinitionId?: Id;
  creditApplicationOrder?: Array<CreditType>;
  daysBeforeBillDue?: number;
}

export interface AccountPlan extends Entity {
  accountId: Id;
  startDate: DateTimeISOString;
  billEpoch?: DateString;
  childBillingMode?: ChildBillingMode;
  contractId?: string;
  customFields?: CustomFields;
  endDate?: DateTimeISOString;
  planId?: Id;
  planGroupId?: Id;
  productId?: Id;
}

export interface Address {
  addressLine1: string;
  locality: string;
  region: string;
  postCode: string;
  country: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
}

export interface Aggregation extends AuditedEntity {
  aggregation: AggregationType;
  name: string;
  meterId: Id;
  quantityPerUnit: number;
  rounding: string;
  targetField: string;
  unit: string;
  code: string;
  customFields?: CustomFields;
  segmentedFields?: Array<string>;
  segments?: Array<AggregationSegment>;
  defaultValue?: number;
}

export interface AggregationSegment {
  [meterFieldName: string]: string;
}

export interface Alert extends Entity {
  count: number;
  createdDate: DateTimeISOString;
  description: string;
  details: Array<string>;
  lastUpdatedDate: DateTimeISOString;
  severity: AlertSeverity;
  status: AlertStatus;
  type: AlertType;
}

export interface Balance extends AuditedEntity {
  accountId: Id;
  amount: number;
  currency: CurrencyCode;
  endDate: DateString;
  startDate: DateString;
  code?: string;
  description?: string;
  name?: string;
  rolloverAmount?: number;
  rolloverEndDate?: DateString;
  balanceDrawDownDescription?: string;
  overageSurchargePercent?: number;
  overageDescription?: string;
  productIds?: Array<Id>;
  lineItemTypes?: Array<DrawdownChargeTypes>;
}

export interface BalanceTransaction extends Entity {
  amount: number;
  appliedDate: DateTimeISOString;
  entityType: BalanceTransactionEntityType;
  entityId: Id;
  transactionDate: DateTimeISOString;
  currencyPaid?: CurrencyCode;
  description?: string;
  paid?: number;
  transactionTypeId?: Id;
}

export interface Bill extends AuditedEntity {
  accountId: string;
  accountCode: string;
  billDate: DateString;
  billingEpoch: DateString;
  billingFrequency: Frequency;
  billFrequencyInterval: number;
  billJobId: Id;
  billTotal: number;
  createdDate: DateTimeISOString;
  csvStatementGenerated: boolean;
  currency: CurrencyCode;
  currencyConversions?: Array<CurrencyConversion>;
  dueDate: DateString;
  endDate: DateString;
  endDateTimeUTC: DateTimeISOString;
  externalInvoiceDate: DateString;
  jsonStatementGenerated: boolean;
  lastCalculatedDate: DateTimeISOString;
  lineItems: Array<BillLineItem>;
  locked: boolean;
  startDate: DateString;
  startDateTimeUTC: DateTimeISOString;
  status: BillStatus;
  timezone: string;
  externalInvoiceReference?: string;
  purchaseOrderNumber?: string;
  sequentialInvoiceNumber?: string;
}

export interface BillConfig extends Entity {
  billLockDate?: DateString;
}

export interface BillJob extends Entity {
  status: BillJobStatus;
  type: BillJobType;
  accountIds?: Array<Id>;
  lastDateInBillingPeriod?: DateString;
  billingFrequency?: BillJobFrequency;
  billFrequencyInterval?: number;
  billIds?: Array<Id>;
  billDate?: DateString;
  dueDate?: DateString;
  externalInvoiceDate?: DateString;
  timezone?: string;
  yearEpoch?: DateString;
  monthEpoch?: DateString;
  weekEpoch?: DateString;
  targetCurrency?: CurrencyCode;
  pending?: number;
  total?: number;
  currencyConversions?: Array<CurrencyConversion>;
}

export interface BillBandUsage {
  bandQuantity: number;
  bandSubtotal: number;
  bandUnits: number;
  fixedPrice: number;
  lowerLimit: number;
  unitPrice: number;
  unitSubtotal: number;
}

export interface BillLineItem extends Entity {
  childAccountId?: Id;
  conversionRate: number;
  convertedSubtotal: number;
  currency: CurrencyCode;
  description: string;
  lineItemType: BillLineItemType;
  productId: Id;
  productName: string;
  quantity: number;
  subtotal: number;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  commitmentId?: Id;
  creditTypeId?: Id;
  counterId?: Id;
  meterId?: Id;
  planId?: Id;
  planGroupId?: Id;
  pricingId?: Id;
  reasonId?: Id;
  referencedBillId?: Id;
  referencedLineItemId?: Id;
  sequenceNumber?: number;
  servicePeriodEndDate?: DateTimeISOString;
  servicePeriodStartDate?: DateTimeISOString;
  unit?: string;
  units?: number;
  usagePerPricingBand?: Array<BillBandUsage>;
}

export interface Commitment extends AuditedEntity {
  accountId: Id;
  amount: number;
  amountPrePaid: number;
  currency: CurrencyCode;
  endDate: string;
  overageSurchargePercent: number;
  startDate: string;
  accountingProductId?: Id;
  amountFirstBill?: number;
  billEpoch?: DateString;
  billingInterval?: number;
  billingOffset?: number;
  billingPlanId?: Id;
  childBillingMode?: ChildBillingMode;
  commitmentFeeBillInAdvance?: boolean;
  commitmentFeeDescription?: string;
  commitmentUsageDescription?: string;
  contractId?: string;
  feeDates?: Array<CommitmentFeeDate>;
  overageDescription?: string;
  productIds?: Array<Id>;
  separateOverageUsage: boolean;
  lineItemTypes?: Array<DrawdownChargeTypes>;
}

export interface CommitmentFeeDate {
  date: DateString;
  amount: number;
  servicePeriodStartDate: DateTimeISOString;
  servicePeriodEndDate: DateTimeISOString;
}

export interface CompoundAggregation extends AuditedEntity {
  calculation: string;
  name: string;
  quantityPerUnit: number;
  rounding: string;
  unit: string;
  code?: string;
  productId?: string;
  segments?: Array<AggregationSegment>;
  customFields?: CustomFields;
  evaluateNullAggregations?: boolean;
}

export interface Contract extends AuditedEntity {
  accountId: string;
  endDate: DateTimeISOString;
  name: string;
  startDate: DateTimeISOString;
  code?: string;
  description?: string;
  purchaseOrderNumber?: string;
}

export interface Counter extends AuditedEntity {
  code: string;
  name: string;
  productId?: Id;
  unit: string;
}

export interface CounterAdjustment extends AuditedEntity {
  counterId: Id;
  accountId: Id;
  date: DateString;
  value: number;
  purchaseOrderNumber?: string;
}

export interface CounterPricing extends PricingCommon {
  counterId: Id;
  runningTotalBillInAdvance?: boolean;
  proRateRunningTotal: boolean;
  proRateAdjustmentDebit: boolean;
  proRateAdjustmentCredit: boolean;
}

export interface CreditLineItem extends Entity {
  amount: number;
  creditReasonId: Id;
  description: string;
  productId?: Id;
  referencedBillId?: Id;
  referencedLineItemId?: Id;
  servicePeriodStartDate?: DateTimeISOString;
  servicePeriodEndDate?: DateTimeISOString;
}

export interface CreditReason extends PickListEntity {}

export interface Currency extends PickListEntity {
  maxDecimalPlaces: number;
}

export interface CurrencyConversion {
  from: CurrencyCode;
  to: CurrencyCode;
  multiplier: number;
}

export interface Customer extends Entity {
  name: string;
}

export type CustomFields = Record<string, string | number>;

export type CustomFieldDefaultsKey =
  | 'account'
  | 'accountPlan'
  | 'aggregation'
  | 'compoundAggregation'
  | 'meter'
  | 'organization'
  | 'product'
  | 'plan'
  | 'planTemplate';

export interface CustomFieldDefaults
  extends Entity,
    Partial<Record<CustomFieldDefaultsKey, CustomFields>> {}

export interface DebitLineItem extends Entity {
  amount: number;
  debitReasonId: Id;
  description: string;
  productId?: Id;
  referencedBillId?: Id;
  referencedLineItemId?: Id;
  servicePeriodStartDate?: DateTimeISOString;
  servicePeriodEndDate?: DateTimeISOString;
}

export interface DebitReason extends PickListEntity {}

export type DefaultCustomFields = Entity & CustomFields;

export interface EventTypes {
  events: Array<string>;
}

export type IngestEvent = M3terEvent<{
  code: string;
  eventType: string;
  generateDownloadUrl: string;
  orgId: Id;
}>;

export type IngestMeasurementDimension = Lowercase<MeterFieldCategory>;

export type IngestMeasurementRecords = {
  [dimension in IngestMeasurementDimension]?: {
    [measurementFieldName: string]: string | number;
  };
};

export interface IngestMeasurementDatum extends IngestMeasurementRecords {
  uid: string;
  meter: string;
  account: string;
  ts: string;
  ets?: string;
}

export type IntegrationsEvent = M3terEvent<{
  integrationRunId: Id;
  requestType: string;
  actionUrl: string;
  destination: string;
  eventTime: DateTimeISOString;
  eventName: string;
  entityId: Id;
  actionDescription: string;
  eventType: string;
  humanReadableMessage: string;
  orgId: Id;
}>;

export interface Invitation extends Entity {
  email: string;
  permissionPolicyIds?: Array<Id>;
  dtEndAccess?: DateTimeISOString;
  dtExpiry: DateTimeISOString;
  invitingPrincipalId: Id;
  accepted: boolean;
}

// Some of these entity properties look like they're duplicated, however this is because this event is in reference to
// another event that was fired. That was the action event and this is the details' event, so we carry over the
// properties within the action event to display in the details' event, all these properties have different values on the
// entity. They do share the same entityId but that relationship is not related to action event.
export interface M3terEvent<T = Record<string, any>> extends Entity {
  eventName: string;
  eventTime: DateTimeISOString;
  m3terEvent: M3terEventDetails<T>;
  dtActioned?: DateTimeISOString;
}

export interface M3terEventDetails<T> {
  eventData: T;
  eventTime: DateTimeISOString;
  eventName: string;
}

export interface MeterDataField {
  code: string;
  name: string;
  category: MeterFieldCategory;
  unit?: string;
}

export interface MeterDerivedField extends MeterDataField {
  calculation: string;
}

export interface Meter extends AuditedEntity {
  code: string;
  dataFields: Array<MeterDataField>;
  derivedFields: Array<MeterDerivedField>;
  name: string;
  customFields?: CustomFields;
  groupId?: Id;
  productId?: Id;
}

export type NotificationEvent = M3terEvent<{
  calculation: string;
  eventId: Id;
  notificationConfigurationId: Id;
  accountId: Id;
  name: string;
  eventName: string;
  description: string;
  eventType: string;
  id: Id;
  orgId: Id;
  notificationCode: string;
}>;

export interface NotificationRule extends Entity {
  name: string;
  description: string;
  active: boolean;
  alwaysFireEvent: boolean;
  eventName: string;
  calculation: string;
  code: string;
}

export interface OrganizationCommon extends Entity, Address {
  orgId: Id;
  organizationName: string;
  shortName: string;
  customerId?: Id;
}

export interface Organization extends OrganizationCommon {
  purchaseOrderNumber?: string;
  customFields?: CustomFields;
}

export interface OrganizationAdmin extends OrganizationCommon {
  ownerId?: Id;
}

export enum ExternalInvoiceDate {
  FirstDayOfNextPeriod = 'FIRST_DAY_OF_NEXT_PERIOD',
  LastDayOfArrears = 'LAST_DAY_OF_ARREARS',
}

export interface OrganizationConfig extends Entity {
  currency: CurrencyCode;
  timezone: string;
  yearEpoch: DateString;
  monthEpoch: DateString;
  weekEpoch: DateString;
  dayEpoch: DateString;
  daysBeforeBillDue: number;
  commitmentFeeBillInAdvance: boolean;
  minimumSpendBillInAdvance: boolean;
  scheduledBillInterval: number;
  standingChargeBillInAdvance: boolean;
  externalInvoiceDate: ExternalInvoiceDate;
  suppressedEmptyBills: boolean;
  consolidateBills: boolean;
  autoApproveBillsGracePeriod?: number;
  autoApproveBillsGracePeriodUnit?: AutoApprovePeriodUnit;
  billPrefix?: string;
  currencyConversions?: Array<CurrencyConversion>;
  defaultStatementDefinitionId?: Id;
  autoGenerateStatementMode?: StatementAutoGenerateMode;
  sequenceStartNumber?: number;
  creditApplicationOrder?: Array<CreditType>;
}

export interface PermissionPolicy extends AuditedEntity {
  name: string;
  permissionPolicy: Array<PermissionPolicyStatement>;
  managedPolicy: boolean;
}

export interface PermissionPolicyStatement {
  effect: PermissionEffect;
  action: Array<string>;
  resource: Array<string>;
}

export interface PlanAndPlanTemplateCommon {
  standingCharge: number;
  minimumSpend?: number;
  minimumSpendBillInAdvance?: boolean;
  minimumSpendDescription?: string;
  standingChargeBillInAdvance?: boolean;
  standingChargeDescription?: string;
}

export interface Plan
  extends AuditedEntity,
    Partial<PlanAndPlanTemplateCommon> {
  code: string;
  name: string;
  planTemplateId: Id;
  accountId?: Id;
  customFields?: CustomFields;
  ordinal?: number;
  productId?: Id;
  // These fields are not in the API response but used in interceptors.
  transactionFixedAggregationId?: Id;
  transactionPercentAggregationId?: Id;
}

export interface PlanTemplate extends AuditedEntity, PlanAndPlanTemplateCommon {
  currency: CurrencyCode;
  billFrequency: Frequency;
  billFrequencyInterval: number;
  name: string;
  ordinal: number;
  productId: Id;
  standingChargeInterval: number;
  standingChargeOffset: number;
  code?: string;
  customFields?: CustomFields;
}

export interface PlanGroup extends AuditedEntity {
  code: string;
  currency: CurrencyCode;
  name: string;
  accountId?: Id;
  minimumSpend?: number;
  minimumSpendAccountingProductId?: Id;
  minimumSpendBillInAdvance?: boolean;
  minimumSpendDescription?: string;
  standingCharge?: number;
  standingChargeAccountingProductId?: Id;
  standingChargeBillInAdvance?: boolean;
  standingChargeDescription?: string;
  customFields?: CustomFields;
}

export interface PlanGroupLink extends Entity {
  planGroupId: string;
  planId: string;
}

export interface Pricing extends PricingCommon {
  tiersSpanPlan: boolean;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  minimumSpend?: number;
  minimumSpendBillInAdvance?: boolean;
  minimumSpendDescription?: string;
  overagePricingBands?: Array<PricingBand>;
  type: ApplyAsPricingType;
  segment?: AggregationSegment;
  // This isn't in the API data but is used by interceptors.
  relatedPricingId?: Id;
}

export interface PricingBand {
  id?: Id;
  lowerLimit: number;
  fixedPrice: number;
  unitPrice: number;
  // This isn't in the API data but is used by interceptors.
  percentagePrice?: number;
}

export interface PricingCommon extends AuditedEntity {
  cumulative: boolean;
  description?: string;
  endDate?: DateTimeISOString;
  planId?: Id;
  planTemplateId?: Id;
  pricingBands?: Array<PricingBand>;
  startDate: DateTimeISOString;
}

export interface PrincipalPermission extends AuditedEntity {
  permissionPolicyId: Id;
  principalId: Id;
  principalType: string;
}

export interface Product extends AuditedEntity {
  code: string;
  name: string;
  customFields?: CustomFields;
}

export interface ResourceGroup extends AuditedEntity {
  name: string;
}

export type SegmentedAggregation = WithRequired<
  Aggregation,
  'segmentedFields' | 'segments'
>;

export type SegmentedCompoundAggregation = WithRequired<
  CompoundAggregation,
  'segments'
>;

export interface ServiceUser extends AuditedEntity {
  name: string;
}

export interface ServiceUserCredential {
  apiKey: string;
  apiSecret: string;
  inactive: boolean;
}

export interface StatementDefinition extends AuditedEntity {
  name: string;
  aggregationFrequency: StatementAggregationFrequency;
  includePricePerUnit: boolean;
  dimensions: Array<StatementDefinitionDimension>;
  measures: Array<StatementDefinitionMeasure>;
}

export interface StatementDefinitionDimension {
  meterId: Id;
  name: string;
  filter: Array<string>;
}

export interface StatementDefinitionMeasure {
  meterId: Id;
  name: string;
  aggregations: Array<string>;
}

export interface StatementJob extends Entity {
  billId: Id;
  includeCsvFormat: boolean;
  statementJobStatus: StatementJobStatus;
  presignedJsonStatementUrl?: string;
  statementId?: Id;
}

export interface SupportAccess extends Entity {
  dtEndSupportUsersAccess?: DateString;
  supportUsersPermissionPolicy?: Array<PermissionPolicyStatement>;
}

export interface TransactionType extends PickListEntity {}

export interface UserCommon extends AuditedEntity {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  supportUser: boolean;
}

export interface User extends UserCommon {
  dtEndAccess?: DateTimeISOString;
  firstAcceptedTermsAndConditions?: DateTimeISOString;
  lastAcceptedTermsAndConditions?: DateTimeISOString;
}

export interface UserAdmin extends UserCommon {
  tempPassword?: string;
}

export interface UserGroup extends ResourceGroup {}

export type UserInvite = Omit<
  User,
  | 'supportUser'
  | 'firstAcceptedTermsAndConditions'
  | 'lastAcceptedTermsAndConditions'
> & {
  permissionPolicyIds: Array<Id>;
  dtExpiry: DateTimeISOString;
};
