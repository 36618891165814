import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  acceptTerms as acceptTermsAction,
  selectIsAcceptingTerms,
  selectTermsError,
} from '@/store/app/auth/auth';

const useTerms = () => {
  const dispatch = useDispatch();

  const acceptTerms = useCallback(() => {
    dispatch(acceptTermsAction());
  }, [dispatch]);

  const isAcceptingTerms = useSelector(selectIsAcceptingTerms);
  const termsError = useSelector(selectTermsError);

  return {
    acceptTerms,
    isAcceptingTerms,
    termsError,
  };
};

export default useTerms;
