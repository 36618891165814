import React from 'react';

import { NamedRoute } from '@/types/routes';

import { ReportsRootRoute } from '@/components/routes/reports/ReportsRootRoute';
import { BillingReportsListRoute } from '@/components/routes/billing/reports/BillingReportsListRoute';

import setupBillingBasedRecurringRevenueReportRoute from './billingBasedRecurringRevenue';
import setupMonthOnMonthReportRoute from './monthOnMonth';
import setupRecurringRevenueReportRoute from './recurringRevenue';

export default (): NamedRoute => ({
  name: 'billing.reports',
  path: 'reports',
  element: <ReportsRootRoute />,
  children: [
    { index: true, element: <BillingReportsListRoute /> },
    setupBillingBasedRecurringRevenueReportRoute(),
    setupMonthOnMonthReportRoute(),
    setupRecurringRevenueReportRoute(),
  ],
});
