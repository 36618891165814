import React from 'react';

import { Link, To, useMatch, useResolvedPath } from 'react-router-dom';
import { forwardRef } from '@chakra-ui/react';

import { NavigationItem, NavigationItemProps } from '@m3ter-com/ui-components';

export interface NavLinkProps extends NavigationItemProps {
  to: To;
  activeOnlyIfExact?: boolean;
}

export const NavLink = forwardRef<NavLinkProps, 'a'>(
  ({ to, activeOnlyIfExact = false, ...props }, ref) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: activeOnlyIfExact });

    return (
      <NavigationItem
        ref={ref}
        as={Link}
        isActive={!!match}
        to={to}
        {...props}
      />
    );
  }
);
