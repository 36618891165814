import {
  call,
  delay,
  put,
  race,
  select,
  StrictEffect,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import difference from 'lodash/difference';
import i18next from 'i18next';
import { formatInTimeZone, toDate } from 'date-fns-tz';

import {
  DataType,
  BillJob,
  BillJobType,
  Id,
  create,
  update,
} from '@m3ter-com/m3ter-api';
import {
  isInDateRange,
  shortDateFormatKey,
} from '@m3ter-com/console-core/utils';

import { Task } from '@/types/tasks';
import { EntityRouteListIds, OtherListIds } from '@/types/lists';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import {
  isBootstrapSuccessActionWithOrgId,
  selectCurrentOrgId,
  selectOrgTimezone,
} from '@/store/app/bootstrap/bootstrap';
import { dataLoaded, dataUpdated, selectById } from '@/store/data/data';
import { listAllData, listData } from '@/store/data/data.saga';
import {
  cancelTask,
  CancelTaskAction,
  removeTask,
  upsertTasks,
} from '@/store/tasks/tasks';
import { List, refreshList, selectListState } from '@/store/crud';
import {
  selectSelectedStartDate,
  selectSelectedEndDate,
} from '@/store/features/billing/billsList';

import {
  billJobComplete,
  BillJobCompleteAction,
  generateBills,
  GenerateBillsAction,
  generateBillsFailure,
  generateBillsSuccess,
  recalculateBills,
  RecalculateBillsAction,
  recalculateBillsFailure,
  recalculateBillsSuccess,
  selectRunningBillJobIds,
  setRunningBillJobs,
} from './billJobs';

const MAX_BILL_JOBS = 10;
const BILL_JOB_ACTIVE_POLLING_DELAY = 5000;
const BILL_JOB_INACTIVE_POLLING_DELAY = 30000;
const TASK_ID_PREFIX = 'billJob-';

const getTaskId = (id: Id) => `${TASK_ID_PREFIX}${id}`;

const createTask = (billJob: BillJob): Task => {
  const { total, pending } = billJob;
  const hasCounts = !!total && !!pending;

  return {
    id: getTaskId(billJob.id),
    title: i18next.t('common:billJob'),
    details: hasCounts
      ? i18next.t<string, string>('features:billing.billJobProgress', {
          count: total - pending,
          total: billJob.total,
        })
      : i18next.t<string, string>(
          `features:billing.billJobStatus.${billJob.status}`
        ),
    cancellable: true,
    progress: hasCounts ? (100 * (total - pending)) / total : undefined,
  };
};

export function* pollBillJobsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const billJobs = yield call(updateBillJobsSaga);

    // Wait for either a delay or a new bill job being created.
    yield race([
      delay(
        // Poll more frequently if there are running bill jobs.
        billJobs.length > 0
          ? BILL_JOB_ACTIVE_POLLING_DELAY
          : BILL_JOB_INACTIVE_POLLING_DELAY
      ),
      take([generateBillsSuccess.type, recalculateBillsSuccess.type]),
    ]);
  }
}

export function* updateBillJobsSaga(): Generator<
  StrictEffect,
  Array<BillJob>,
  any
> {
  try {
    const response = yield call(listData, DataType.BillJob, {
      active: true,
      pageSize: MAX_BILL_JOBS,
    });
    const billJobs: Array<BillJob> = response.data;
    const billJobIds = ids(billJobs);

    const runningBillJobIds = yield select(selectRunningBillJobIds);

    // Check for any removed running bill jobs that we can consider complete.
    const removedBillJobIds = difference(runningBillJobIds, billJobIds);

    for (let i = 0; i < removedBillJobIds.length; i += 1) {
      const billJobSelector = yield call(
        selectById,
        DataType.BillJob,
        removedBillJobIds[i]
      );
      const billJob = yield select(billJobSelector);
      yield put(billJobComplete(billJob));
      yield put(removeTask(getTaskId(removedBillJobIds[i])));
    }

    // Update / add all running bill jobs.
    yield put(setRunningBillJobs(billJobIds));

    if (billJobs.length > 0) {
      yield put(upsertTasks(billJobs.map(createTask)));
    }

    return billJobs;
  } catch {
    // Error likely to be a network issue or auth session timeout.
    // We can skip the update and the polling will retry the call.
    return [];
  }
}

export function* cancelTaskSaga(
  action: CancelTaskAction
): Generator<StrictEffect, void, any> {
  const { id } = action.payload;
  const organizationId = yield select(selectCurrentOrgId);
  if (id.startsWith(TASK_ID_PREFIX)) {
    const billJobId = id.replace(TASK_ID_PREFIX, '');
    try {
      const updatedBillJob: BillJob = yield call(update, {
        dataType: DataType.BillJob,
        actionName: 'cancelBillJob',
        id: billJobId,
        pathParams: { organizationId },
      });
      yield put(dataUpdated(DataType.BillJob, [updatedBillJob]));
    } catch (error) {
      // Bill job likely to have completed in the meantime.
    }
    yield put(removeTask(id));
  }
}

// Creates a bill job to generate bills for a given period (by invoice date and
// billing frequency) and then adds that bill job to the polling queue.
export function* generateBillsSaga(
  action: GenerateBillsAction
): Generator<StrictEffect, void, any> {
  try {
    const organizationId = yield select(selectCurrentOrgId);
    const orgTimezone = yield select(selectOrgTimezone);

    const {
      externalInvoiceDate,
      billingFrequency,
      accountIds,
      targetCurrency,
    } = action.payload;

    const billJob: BillJob = yield call(create, {
      dataType: DataType.BillJob,
      actionName: 'generateBills',
      pathParams: { organizationId },
      data: {
        externalInvoiceDate: formatInTimeZone(
          externalInvoiceDate,
          orgTimezone,
          shortDateFormatKey
        ),
        billingFrequency,
        accountIds,
        targetCurrency,
      },
    });
    // Manually add the bill job to the data slice.
    yield put(dataLoaded(DataType.BillJob, [billJob]));

    yield put(generateBillsSuccess(billJob));
  } catch (error) {
    yield put(generateBillsFailure(extractError(error)));
  }
}

// Creates a bill job to recalulate a set of bills, defined by their IDs
// in the action payload and then adds that bill job to the polling queue.
export function* recalculateBillsSaga(
  action: RecalculateBillsAction
): Generator<StrictEffect, void, any> {
  const { billIds } = action.payload;
  try {
    const organizationId = yield select(selectCurrentOrgId);

    const billJob = yield call(create, {
      dataType: DataType.BillJob,
      actionName: 'recalculateBills',
      pathParams: {
        organizationId,
      },
      data: { billIds },
    });
    // Manually add the bill job to the data slice.
    yield put(dataLoaded(DataType.BillJob, [billJob]));

    yield put(recalculateBillsSuccess(billJob));
  } catch (error) {
    yield put(recalculateBillsFailure(extractError(error), billIds));
  }
}

export function* billJobCompleteSaga(
  action: BillJobCompleteAction
): Generator<StrictEffect, void, any> {
  const { billJob } = action.payload;

  if (billJob.type === BillJobType.Create) {
    // Update bills list if a completed bill job is within date range.
    const selectedStartDate: string | undefined = yield select(
      selectSelectedStartDate
    );
    const selectedEndDate: string | undefined = yield select(
      selectSelectedEndDate
    );

    const orgTimeZone = yield select(selectOrgTimezone);

    if (
      billJob.externalInvoiceDate &&
      selectedStartDate &&
      selectedEndDate &&
      isInDateRange(
        toDate(billJob.externalInvoiceDate, { timeZone: orgTimeZone }),
        selectedStartDate,
        selectedEndDate
      )
    ) {
      yield put(refreshList(DataType.Bill, EntityRouteListIds.Bill));
    }

    // Refresh the account bills list if the bill job was for the account.
    const { accountIds } = billJob;
    if (accountIds && accountIds.length > 0) {
      const accountBillsListStateSelector = yield call(
        selectListState,
        DataType.Bill,
        OtherListIds.AccountBills
      );
      const accountBillsListState: List | undefined = yield select(
        accountBillsListStateSelector
      );
      if (accountBillsListState) {
        const accountId = accountBillsListState.queryParams.accountId as
          | Id
          | undefined;
        if (accountId && accountIds.includes(accountId)) {
          yield put(refreshList(DataType.Bill, OtherListIds.AccountBills));
        }
      }
    }
  } else if (billJob.type === BillJobType.Recalculate) {
    // Update bills that have been recalculated.
    const { billIds } = billJob;
    if (billIds && billIds.length > 0) {
      yield call(listAllData, DataType.Bill, {
        ids: billIds,
      });
    }
  }
}

export default function* billJobsSaga() {
  yield takeEvery(cancelTask.type, cancelTaskSaga);

  yield takeEvery(generateBills.type, generateBillsSaga);
  yield takeEvery(recalculateBills.type, recalculateBillsSaga);

  // Even though we manually add the running job and task after
  // these actions, leaving this reload will mark any very fast
  // completed bill jobs quicker than waiting for the next poll.
  yield takeEvery(
    [generateBillsSuccess.type, recalculateBillsSuccess.type],
    updateBillJobsSaga
  );

  yield takeEvery(billJobComplete.type, billJobCompleteSaga);

  // `takeLatest` cancels previous tasks if they are still running so
  // each time the org changes the old polling will be stopped.
  // https://redux-saga.js.org/docs/api#takelatestpattern-saga-args
  yield takeLatest(isBootstrapSuccessActionWithOrgId, pollBillJobsSaga);
}
