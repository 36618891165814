import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper';
import { SettingsNavigation } from '@/components/common/navigation/sections/SettingsNavigation';

import setupOrganizationRoute from './organization';
import setupAccessRoute from './access';

export default (): NamedRoute => ({
  path: 'settings',
  name: 'settings',
  element: <RouteWrapper navigation={<SettingsNavigation />} />,
  children: [
    { index: true, element: <Navigate replace to="organization" /> },
    setupOrganizationRoute(),
    setupAccessRoute(),
  ],
});
