import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { OrganizationAdminResendPasswordModalFormValues } from '@/components/features/admin/organizations/OrganizationAdminResendPasswordModal';

const suite = create(
  'organizationResendTempPassword',
  (data: Partial<OrganizationAdminResendPasswordModalFormValues>) => {
    test('userId', i18next.t('forms:validations.admin.userRequired'), () => {
      enforce(data.userId).isNotEmpty();
    });
  }
);

export default suite;
