import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataExplorerUsageMetersResponse,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import {
  DataExplorerBillingDataPivotTableRow,
  DataExplorerBillingDataTableRow,
  DataExplorerCommitmentsDataTableRow,
  DataExplorerCommitmentsLineItemsDataTableRow,
  DataExplorerCommitmentsObligationsDataTableRow,
  DataExplorerDataType,
  DataExplorerDataTypeToRequestBody,
  DataExplorerMeterDimensionDatum,
  DataExplorerUsageDataPivotTableRow,
  DataExplorerUsageDataTableRow,
} from '@/types/data';

export type DataExplorerDataTypeToDataStore = {
  [DataExplorerDataType.BillingData]: {
    tableData: Array<DataExplorerBillingDataTableRow>;
    pivotData: Array<DataExplorerBillingDataPivotTableRow>;
  };
  [DataExplorerDataType.CommitmentsData]: {
    tableData: Array<DataExplorerCommitmentsDataTableRow>;
  };
  [DataExplorerDataType.CommitmentsLineItemsData]: {
    tableData: Array<DataExplorerCommitmentsLineItemsDataTableRow>;
  };
  [DataExplorerDataType.CommitmentsObligationsData]: {
    tableData: Array<DataExplorerCommitmentsObligationsDataTableRow>;
    timestamps: Array<string>;
  };
  [DataExplorerDataType.UsageData]: {
    tableColumns: Array<{ key: string; title: string }>;
    tableData: Array<DataExplorerUsageDataTableRow>;
    pivotData: Array<DataExplorerUsageDataPivotTableRow>;
  };
  [DataExplorerDataType.UsageMeterDimensions]: {
    failures: Array<string>;
    values: Array<DataExplorerMeterDimensionDatum>;
  };
  [DataExplorerDataType.UsageMeters]: DataExplorerUsageMetersResponse;
};

interface BaseDataExplorerPayload<
  DT extends DataExplorerDataType = DataExplorerDataType
> {
  dataType: DT;
}

export interface DataExplorerDataLoadingPayload<
  DT extends DataExplorerDataType = DataExplorerDataType
> extends BaseDataExplorerPayload<DT> {
  body: DataExplorerDataTypeToRequestBody[DT];
  loadExportUrl?: boolean;
  pathParams?: PathParams;
  queryParams?: QueryParams;
}

export interface DataExplorerDataLoadingSuccessPayload<
  DT extends DataExplorerDataType = DataExplorerDataType
> extends BaseDataExplorerPayload<DT> {
  data: DataExplorerDataTypeToDataStore[DT];
}

interface BatchLoadDataExplorerDataPayload {
  actions: Array<DataExplorerDataLoadingPayload>;
}
export type BatchLoadDataExplorerDataAction =
  PayloadAction<BatchLoadDataExplorerDataPayload>;

interface BatchResetDataExplorerStoresPayload {
  dataTypes: Array<DataExplorerDataType>;
}
export type BatchResetDataExplorerStoresAction =
  PayloadAction<BatchResetDataExplorerStoresPayload>;

export type LoadDataExplorerDataAction<DT extends DataExplorerDataType> =
  PayloadAction<DataExplorerDataLoadingPayload<DT>>;
type LoadDataExplorerDataActionCreator = {
  type: string;
  <DT extends DataExplorerDataType>(
    dataType: DT,
    body: DataExplorerDataTypeToRequestBody[DT],
    pathParams?: PathParams,
    queryParams?: QueryParams,
    loadExportUrl?: boolean
  ): LoadDataExplorerDataAction<DT>;
};

export type LoadDataExplorerDataFailureAction = PayloadAction<
  BaseDataExplorerPayload,
  string,
  never,
  AppError
>;

export type LoadDataExplorerDataSuccessAction<DT extends DataExplorerDataType> =
  PayloadAction<DataExplorerDataLoadingSuccessPayload<DT>>;
type LoadDataExplorerDataSuccessActionCreator = {
  type: string;
  <DT extends DataExplorerDataType>(
    dataType: DT,
    data: DataExplorerDataTypeToDataStore[DT]
  ): LoadDataExplorerDataSuccessAction<DT>;
};

export type LoadDataExplorerExportUrlStartedAction =
  PayloadAction<BaseDataExplorerPayload>;

export type LoadDataExplorerExportUrlFailureAction = PayloadAction<
  BaseDataExplorerPayload,
  string,
  never,
  AppError
>;

interface LoadDataExplorerExportUrlSuccessPayload
  extends BaseDataExplorerPayload {
  exportUrl: string;
}
export type LoadDataExplorerExportUrlSuccessAction =
  PayloadAction<LoadDataExplorerExportUrlSuccessPayload>;

export type ResetDataExplorerStoreAction =
  PayloadAction<BaseDataExplorerPayload>;

interface DataExplorerDataStore<D> {
  isLoadingData: boolean;
  isLoadingExportUrl: boolean;
  data?: D;
  dataError?: AppError;
  exportError?: AppError;
  exportUrl?: string;
}
export type DataExplorerState = Partial<{
  [T in DataExplorerDataType]: DataExplorerDataStore<
    DataExplorerDataTypeToDataStore[T]
  >;
}>;

const getDataStore = (
  state: DataExplorerState,
  dataType: DataExplorerDataType
): Required<DataExplorerState>[DataExplorerDataType] => {
  if (!state[dataType]) {
    state[dataType] = {
      isLoadingData: false,
      isLoadingExportUrl: false,
    };
  }

  return state[dataType]!;
};

const cleanDataStore = (
  state: DataExplorerState,
  dataType: DataExplorerDataType
) => {
  if (state[dataType]) {
    const dataStore = state[dataType]!;
    dataStore.data = undefined;
    dataStore.dataError = undefined;
    dataStore.exportError = undefined;
    dataStore.exportUrl = undefined;
    dataStore.isLoadingData = false;
    dataStore.isLoadingExportUrl = false;
  }
};

export const initialState: DataExplorerState = {};
export const name = 'features/analytics/dataExplorer';
const dataExplorerSlice = createSlice({
  name,
  initialState,
  reducers: {
    batchLoadDataExplorerData: {
      reducer: (
        state: DataExplorerState,
        action: BatchLoadDataExplorerDataAction
      ) => {
        action.payload.actions.forEach((act) => {
          const dataStore = getDataStore(state, act.dataType);
          dataStore.isLoadingData = true;
          dataStore.dataError = undefined;
          dataStore.exportError = undefined;
          dataStore.exportUrl = undefined;
          dataStore.isLoadingExportUrl = false;
        });
      },
      prepare: (
        actions: Array<DataExplorerDataLoadingPayload<DataExplorerDataType>>
      ) => ({
        payload: { actions },
      }),
    },
    batchResetDataExplorerStores: {
      reducer: (
        state: DataExplorerState,
        action: BatchResetDataExplorerStoresAction
      ) => {
        action.payload.dataTypes.forEach((dataType) => {
          cleanDataStore(state, dataType);
        });
      },
      prepare: (dataTypes: Array<DataExplorerDataType>) => ({
        payload: { dataTypes },
      }),
    },
    loadDataExplorerData: {
      reducer: <DT extends DataExplorerDataType>(
        state: DataExplorerState,
        action: LoadDataExplorerDataAction<DT>
      ) => {
        const dataStore = getDataStore(state, action.payload.dataType);
        dataStore.isLoadingData = true;
        dataStore.dataError = undefined;
        dataStore.exportError = undefined;
        dataStore.exportUrl = undefined;
        dataStore.isLoadingExportUrl = false;
      },
      prepare: <DT extends DataExplorerDataType>(
        dataType: DT,
        body?: DataExplorerDataTypeToRequestBody[DT],
        pathParams?: PathParams,
        queryParams?: QueryParams,
        loadExportUrl?: boolean
      ) => ({
        payload: { body, dataType, loadExportUrl, pathParams, queryParams },
      }),
    },
    loadDataExplorerDataFailure: {
      reducer: (
        state: DataExplorerState,
        action: LoadDataExplorerDataFailureAction
      ) => {
        const dataStore = getDataStore(state, action.payload.dataType);
        dataStore.data = undefined;
        dataStore.dataError = action.error;
        dataStore.isLoadingData = false;
      },
      prepare: (dataType: DataExplorerDataType, error: AppError) => ({
        payload: { dataType },
        error,
      }),
    },
    loadDataExplorerDataSuccess: {
      reducer: <DT extends DataExplorerDataType>(
        state: DataExplorerState,
        action: LoadDataExplorerDataSuccessAction<DT>
      ) => {
        const dataStore = getDataStore(state, action.payload.dataType);
        dataStore.data = action.payload.data;
        dataStore.isLoadingData = false;
      },
      prepare: (
        dataType: DataExplorerDataType,
        data: DataExplorerDataTypeToDataStore[DataExplorerDataType]
      ) => ({ payload: { dataType, data } }),
    },
    loadDataExplorerExportUrlFailure: {
      reducer: (
        state: DataExplorerState,
        action: LoadDataExplorerExportUrlFailureAction
      ) => {
        const dataStore = getDataStore(state, action.payload.dataType);
        dataStore.exportError = action.error;
        dataStore.isLoadingExportUrl = false;
      },
      prepare: (dataType: DataExplorerDataType, error: AppError) => ({
        error,
        payload: { dataType },
      }),
    },
    loadDataExplorerExportUrlStarted: {
      reducer: (
        state: DataExplorerState,
        action: LoadDataExplorerExportUrlStartedAction
      ) => {
        const dataStore = getDataStore(state, action.payload.dataType);
        dataStore.isLoadingExportUrl = true;
      },
      prepare: (dataType: DataExplorerDataType) => ({ payload: { dataType } }),
    },
    loadDataExplorerExportUrlSuccess: {
      reducer: (
        state: DataExplorerState,
        action: LoadDataExplorerExportUrlSuccessAction
      ) => {
        const dataStore = getDataStore(state, action.payload.dataType);
        dataStore.exportUrl = action.payload.exportUrl;
        dataStore.isLoadingExportUrl = false;
      },
      prepare: (dataType: DataExplorerDataType, exportUrl: string) => ({
        payload: { exportUrl, dataType },
      }),
    },
    resetAnalyticsStore: {
      reducer: (
        state: DataExplorerState,
        action: ResetDataExplorerStoreAction
      ) => {
        cleanDataStore(state, action.payload.dataType);
      },
      prepare: (dataType: DataExplorerDataType) => ({ payload: { dataType } }),
    },
  },
});

// Export actions
export const {
  batchLoadDataExplorerData,
  batchResetDataExplorerStores,
  loadDataExplorerDataFailure,
  loadDataExplorerExportUrlFailure,
  loadDataExplorerExportUrlStarted,
  loadDataExplorerExportUrlSuccess,
  resetAnalyticsStore,
} = dataExplorerSlice.actions;
export const loadDataExplorerData = dataExplorerSlice.actions
  .loadDataExplorerData as LoadDataExplorerDataActionCreator;
export const loadDataExplorerDataSuccess = dataExplorerSlice.actions
  .loadDataExplorerDataSuccess as LoadDataExplorerDataSuccessActionCreator;

// Selectors
const selectDataExplorerState = (state: {
  features: {
    analytics: {
      dataExplorer: DataExplorerState;
    };
  };
}): DataExplorerState => state.features.analytics.dataExplorer;

export const createSelectByDataExplorerDataType = <
  T extends DataExplorerDataType
>(
  dataType: T
) => createSelector(selectDataExplorerState, (state) => state[dataType]);

// Default export is the reducer itself
export default dataExplorerSlice.reducer;
