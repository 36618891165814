import React, { PropsWithChildren, useCallback } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';

import {
  BadgeIconButton,
  BadgeIconButtonProps,
} from '../../controls/Button/BadgeIconButton';

export interface ButtonPanelProps
  extends Pick<BadgeIconButtonProps, 'badge' | 'icon' | 'variant'> {
  title: string;
  onOpen?: () => void;
}

export const ButtonPanel: React.FC<PropsWithChildren<ButtonPanelProps>> = ({
  badge,
  icon,
  title,
  onOpen,
  variant,
  children,
}) => {
  const { isOpen, onOpen: openDrawer, onClose: closeDrawer } = useDisclosure();

  const onButtonClick = useCallback(() => {
    openDrawer();
    if (onOpen) {
      onOpen();
    }
  }, [onOpen, openDrawer]);

  return (
    <React.Fragment>
      <BadgeIconButton
        addTooltip
        aria-label={title}
        badge={badge}
        icon={icon}
        onClick={onButtonClick}
        variant={variant}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            alignItems="center"
            display="flex"
            flexFlow="row nowrap"
            gap={2}
            justifyContent="flex-start"
          >
            {icon}
            <Heading as="h4" fontSize="xl">
              {title}
            </Heading>
          </DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};
