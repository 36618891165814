import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const header: ComponentSingleStyleConfig = {
  baseStyle: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    gap: 12,
    px: 4,
    bg: 'gray.700',
    boxShadow: 'md',
    '> *': {
      flexShrink: 0,
    },
  },
};

export default header;
