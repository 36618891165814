import React, { useCallback, useMemo, useState } from 'react';

import { Params } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Badge,
  Card,
  CardBody,
  HStack,
  Icon,
  StyleProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';

import { Organization } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, SearchBar } from '@m3ter-com/ui-components';

import { PreferenceName } from '@/types/preferences';

import {
  selectStandardOrgs,
  selectSupportOrgs,
} from '@/store/app/bootstrap/bootstrap';
import usePreference from '@/hooks/data/usePreference';
import { NamedLink } from '@/components/common/navigation/NamedLink';

interface OrgSelectionLinkProps extends StyleProps {
  org: Organization;
  isLastAccessed?: boolean;
  isSupportAccess?: boolean;
}

const OrgSelectionLink: React.FC<OrgSelectionLinkProps> = ({
  isLastAccessed = false,
  isSupportAccess = false,
  org,
  ...styleProps
}) => {
  const { t } = useTranslation();

  const linkParams = useMemo<Params>(
    () => ({
      orgId: org.id,
    }),
    [org]
  );

  return (
    <Button
      as={NamedLink}
      name="organization"
      padding={4}
      params={linkParams}
      {...styleProps}
    >
      <HStack alignItems="center" gap={4} justifyContent="flex-start">
        <Text>{org.organizationName}</Text>
        {isLastAccessed && (
          <Badge variant="outline">{t('common:lastAccessed')}</Badge>
        )}
        {isSupportAccess && (
          <Badge variant="outline">{t('features:access.supportAccess')}</Badge>
        )}
      </HStack>
      <Icon as={FaArrowRight} marginLeft="auto" />
    </Button>
  );
};

export const OrgSelectionList: React.FC = () => {
  const { t } = useTranslation();
  const standardOrgs = useSelector(selectStandardOrgs);
  const supportOrgs = useSelector(selectSupportOrgs);

  const [lastAccessedOrgId] = usePreference(
    PreferenceName.LAST_ACCESSED_ORG_ID,
    ''
  );
  const [lastAccessedOrg, lastAccessedIsSupportOrg] = useMemo(() => {
    if (lastAccessedOrgId) {
      let lastOrg: Organization | undefined;
      lastOrg = standardOrgs.find((org) => org.id === lastAccessedOrgId);
      if (lastOrg) {
        return [lastOrg, false];
      }

      lastOrg = supportOrgs.find((org) => org.id === lastAccessedOrgId);
      if (lastOrg) {
        return [lastOrg, true];
      }
    }

    return [undefined, false];
  }, [lastAccessedOrgId, standardOrgs, supportOrgs]);

  const [searchQuery, setSearchQuery] = useState('');
  const onClearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  const visibleStandardOrgs = useMemo<Array<Organization>>(
    () =>
      standardOrgs
        .filter(
          (org) =>
            (!searchQuery ||
              org.organizationName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) &&
            org.id !== lastAccessedOrgId
        )
        .sort((orgA, orgB) =>
          orgA.organizationName.localeCompare(orgB.organizationName)
        ),
    [lastAccessedOrgId, searchQuery, standardOrgs]
  );
  const visibleSupportOrgs = useMemo<Array<Organization>>(
    () =>
      supportOrgs
        .filter(
          (org) =>
            (!searchQuery ||
              org.organizationName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) &&
            org.id !== lastAccessedOrgId
        )
        .sort((orgA, orgB) =>
          orgA.organizationName.localeCompare(orgB.organizationName)
        ),
    [lastAccessedOrgId, searchQuery, supportOrgs]
  );

  return (
    <Card height="80vh" width="60vw" marginInline="auto">
      <CardBody background="inherit" height="100%" paddingX={0} paddingY={8}>
        <VStack
          alignItems="stretch"
          background="inherit"
          gap={2}
          height="100%"
          justifyContent="flex-start"
          paddingX={8}
          overflowY="scroll"
          position="relative"
          width="100%"
          zIndex={0}
        >
          <VStack
            alignItems="stretch"
            background="inherit"
            gap={4}
            justifyContent="flex-start"
            paddingY={2}
            position="sticky"
            top="0"
            width="100%"
            zIndex={1}
          >
            <Text>{t('features:organizations.selectOrganization')}:</Text>
            {lastAccessedOrg && (
              <OrgSelectionLink
                isLastAccessed
                isSupportAccess={lastAccessedIsSupportOrg}
                marginBottom={8}
                org={lastAccessedOrg}
              />
            )}
            <SearchBar
              searchOnType
              onClear={onClearSearch}
              onSearch={setSearchQuery}
              placeholder={t('common:searchEntity', {
                entity: '$t(common:organizations)',
              })}
            />
          </VStack>
          {visibleStandardOrgs.map((org) => (
            <OrgSelectionLink key={org.id} org={org} />
          ))}
          {visibleSupportOrgs.map((org) => (
            <OrgSelectionLink isSupportAccess key={org.id} org={org} />
          ))}
        </VStack>
      </CardBody>
    </Card>
  );
};
