import { DataType } from '@m3ter-com/m3ter-api';

export const canUsePlanEditor = (
  missingDataTypes?: Array<DataType>
): boolean => {
  if (!missingDataTypes) {
    return false;
  }
  const hasPlans =
    !missingDataTypes?.includes(DataType.PlanTemplate) &&
    !missingDataTypes?.includes(DataType.Plan);
  const hasCompoundAggregations = !missingDataTypes?.includes(
    DataType.CompoundAggregation
  );
  const hasAggregations =
    !missingDataTypes?.includes(DataType.Meter) &&
    !missingDataTypes?.includes(DataType.Aggregation);
  const hasCounters = !missingDataTypes?.includes(DataType.Counter);

  return (
    hasPlans && (hasCompoundAggregations || hasAggregations || hasCounters)
  );
};
