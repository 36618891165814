import {
  call,
  put,
  StrictEffect,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { actionEvents, DataType } from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { refreshList } from '@/store/crud';

import {
  markEventsAsActioned,
  markEventsAsActionedFailure,
  markEventsAsActionedSuccess,
  MarkEventsAsActionedAction,
} from './eventsList';

export function* markEventsAsActionedSaga(
  action: MarkEventsAsActionedAction
): Generator<StrictEffect, void, any> {
  try {
    const organizationId = yield select(selectCurrentOrgId);

    yield call(actionEvents, organizationId, action.payload.eventIds);
    if (action.payload.listId) {
      yield put(refreshList(DataType.M3terEvent, action.payload.listId));
    }
    yield put(markEventsAsActionedSuccess());
  } catch (error) {
    yield put(markEventsAsActionedFailure(extractError(error)));
  }
}

export default function* eventsListSaga() {
  yield takeLatest(markEventsAsActioned.type, markEventsAsActionedSaga);
}
