import React, { useCallback } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  ButtonGroup,
  CloseButton,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { captureFeedback } from '@sentry/react';

import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormField,
  FormTextarea,
} from '@m3ter-com/console-core/components';

import { selectUser } from '@/store/app/auth/auth';
import useNotification from '@/hooks/util/useNotification';
import usePreference from '@/hooks/data/usePreference';

interface FeedbackFormValues {
  message: string;
}

const initialValues: Partial<FeedbackFormValues> = {};

export const FeedbackBanner: React.FC = () => {
  const { t } = useTranslation();

  const [dismissed, setDismissed] = usePreference(
    'new-console-banner-dismissed',
    false
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { toast } = useNotification();

  const user = useSelector(selectUser);

  const onSubmit = useCallback(
    (data: FeedbackFormValues) => {
      if (data.message !== '') {
        captureFeedback({
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          message: data.message,
        });
        toast({
          status: 'success',
          description: t('features:preview.feedbackSent'),
          duration: 5000,
        });
        onClose();
      }
    },
    [user, toast, t, onClose]
  );

  const onDismiss = useCallback(() => {
    setDismissed(true);
  }, [setDismissed]);

  return !dismissed ? (
    <React.Fragment>
      <Alert mb={2}>
        <AlertIcon />
        <Box>
          <AlertTitle>{t('features:preview.previewBannerTitle')}</AlertTitle>
          <AlertDescription>
            {t('features:preview.previewBannerDescription')}
          </AlertDescription>
        </Box>
        <Spacer />
        <ButtonGroup size="sm" ml={4}>
          <Button as="a" href="https://v1.console.m3ter.com">
            {t('features:preview.switchBack')}
          </Button>
          <Button onClick={onOpen} size="sm" colorScheme="blue">
            {t('features:preview.shareFeedback')}
          </Button>
          <CloseButton onClick={onDismiss} />
        </ButtonGroup>
      </Alert>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Form onSubmit={onSubmit} initialValues={initialValues}>
            <ModalHeader>
              <Heading size="sm">{t('features:preview.feedbackTitle')}</Heading>
            </ModalHeader>
            <ModalBody>
              <Text mb={4}>{t('features:preview.feedbackMessage')}</Text>
              <FormField
                hideLabel
                name="message"
                label={t('forms:labels.message')}
                control={FormTextarea}
                rows={6}
              />
            </ModalBody>
            <ModalFooter>
              <FormActions
                submitText={t('common:send')}
                cancelText={t('common:cancel')}
                onCancel={onClose}
              />
            </ModalFooter>
          </Form>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </React.Fragment>
  ) : null;
};
