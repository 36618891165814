import { Auth } from 'aws-amplify';

import { EnvironmentConfig } from '@/config';
import { federatedSignInHelper } from '@/util/auth';

const setupAmplify = (config: EnvironmentConfig) => {
  Auth.configure({
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
    oauth: {
      domain: config.cognito.domain,
      redirectSignIn: `${window.location.protocol}//${window.location.host}`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      responseType: 'code',
      urlOpener: federatedSignInHelper,
    },
  });
};

export default setupAmplify;
