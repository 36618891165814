import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['panel', 'actions'];

const NAV_WIDTH = '280px';
const NAV_WIDTH_COLLAPSED = '50px';

const navigationPanel: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    panel: {
      width: props.isCollapsed ? NAV_WIDTH_COLLAPSED : NAV_WIDTH,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      alignItems: 'stretch',
      py: 4,
      bg: 'nav-bg',
    },
    toggle: {
      flexShrink: 0,
      alignSelf: 'end',
      mr: props.isCollapsed ? 1 : 3,
      bg: mode('gray.200', 'gray.800')(props),
    },
    actions: {
      display: props.isCollapsed ? 'none' : undefined,
      mx: 4,
      p: 4,
      bg: 'nav-actions-bg',
      borderRadius: 'md',
    },
  }),
};

export default navigationPanel;
