import React from 'react';

import { NamedRoute } from '@/types/routes';

import { ReportsRootRoute } from '@/components/routes/reports/ReportsRootRoute';
import { AccountsReportsListRoute } from '@/components/routes/accounts/reports/AccountsReportsListRoute';

import setupPrepaymentsStatusReportRoute from './prepaymentsStatus';
import setupTotalContractValueReportRoute from './totalContractValue';

export default (): NamedRoute => ({
  name: 'accounts.reports',
  path: 'reports',
  element: <ReportsRootRoute />,
  children: [
    { index: true, element: <AccountsReportsListRoute /> },
    setupPrepaymentsStatusReportRoute(),
    setupTotalContractValueReportRoute(),
  ],
});
