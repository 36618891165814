import { AnalyticsJob, AnalyticsJobType, Id } from '../types';

import { getOrganizationPath } from '../util/path';
import { get } from '../client';

export const getAnalyticsJobWithDownloadUrl = <T extends AnalyticsJobType>(
  organizationId: Id,
  jobId: Id
) =>
  get({
    path: getOrganizationPath('/analytics/jobs/:jobId/csv'),
    pathParams: { organizationId, jobId },
  }) as Promise<AnalyticsJob<T>>;
