import React from 'react';

import { UserAdmin } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { UserAdminDetails } from '@/components/features/admin/users/UserAdminDetails';

export const UserAdminDetailsRoute: React.FC = () => (
  <CrudDetails<UserAdmin> component={UserAdminDetails} />
);
