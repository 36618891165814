import {
  DataExplorerUsageDataRequestBody,
  DataExplorerUsageDataResponse,
  DataExplorerUsageMeterDimensionsRequestBody,
  DataExplorerUsageMeterDimensionsResponse,
  DataExplorerUsageMetersResponse,
  Id,
  QueryParams,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

export const getDataExplorerUsageData = (
  organizationId: Id,
  body: DataExplorerUsageDataRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/usagedata'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerUsageDataResponse>;

export const getDataExplorerUsageMeters = (
  organizationId: Id,
  queryParams?: QueryParams
) =>
  get({
    path: getOrganizationPath('/dataexplorer/meters'),
    pathParams: { organizationId },
    queryParams,
  }) as Promise<DataExplorerUsageMetersResponse>;

export const getDataExplorerUsageMeterDimensionValues = (
  organizationId: Id,
  body: DataExplorerUsageMeterDimensionsRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/mergedmeterdimensions'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerUsageMeterDimensionsResponse>;
