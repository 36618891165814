import React from 'react';

export const CaretDownIcon: React.FC = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M2.98351 4.5H9.01398C9.43117 4.5 9.63976 5.00391 9.34445 5.29922L6.33039 8.31562C6.14757 8.49844 5.84992 8.49844 5.6671 8.31562L2.65304 5.29922C2.35773 5.00391 2.56632 4.5 2.98351 4.5Z"
      fill="currentColor"
    />
  </svg>
);
