import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { OrganizationIndexRoute } from '@/components/routes/organization/OrganizationIndexRoute';

import setupAccountsRoute from './accounts';
import setupBillingRoute from './billing';
import setupEventsRoute from './events';
import setupIntegrationsRoute from './integrations';
import setupPricingRoute from './pricing';
import setupProductsRoute from './products';
import setupSettingRoute from './settings';
import setupUsageRoute from './usage';

export const ROOT_ORG_PATH = 'org';

export default (): NamedRoute => ({
  name: 'organization',
  path: `${ROOT_ORG_PATH}/:orgId`,
  children: [
    {
      name: 'dashboard',
      index: true,
      element: <OrganizationIndexRoute />,
    },
    // Redirect from old reports route to billing reports.
    {
      path: 'reports',
      element: <Navigate replace to="../billing/reports" />,
    },
    setupAccountsRoute(),
    setupBillingRoute(),
    setupEventsRoute(),
    setupIntegrationsRoute(),
    setupPricingRoute(),
    setupProductsRoute(),
    setupSettingRoute(),
    setupUsageRoute(),
  ],
});
