import React, { useCallback } from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField, FormInput } from '@m3ter-com/console-core/components';

export interface AddressFieldsProps {
  isRequired?: boolean;
  keyPrefix?: string;
  showExtraAddressLines?: boolean;
}

export const AddressFields: React.FC<AddressFieldsProps> = ({
  keyPrefix,
  isRequired = true,
  showExtraAddressLines = false,
}) => {
  const getName = useCallback(
    (field: string) => (keyPrefix ? `${keyPrefix}.${field}` : field),
    [keyPrefix]
  );

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormField
        isRequired={isRequired}
        name={getName('addressLine1')}
        label={t('forms:labels.addressComponents.lineOne')}
        control={FormInput}
      />
      <FormField
        isRequired={isRequired}
        name={getName('addressLine2')}
        label={t('forms:labels.addressComponents.lineTwo')}
        control={FormInput}
      />
      {showExtraAddressLines && (
        <React.Fragment>
          <FormField
            name={getName('addressLine3')}
            label={t('forms:labels.addressComponents.lineThree')}
            control={FormInput}
          />
          <FormField
            name={getName('addressLine4')}
            label={t('forms:labels.addressComponents.lineFour')}
            control={FormInput}
          />
        </React.Fragment>
      )}
      <FormField
        name={getName('locality')}
        label={t('forms:labels.addressComponents.locality')}
        control={FormInput}
      />
      <FormField
        name={getName('region')}
        label={t('forms:labels.addressComponents.region')}
        control={FormInput}
      />
      <FormField
        isRequired={isRequired}
        name={getName('postCode')}
        label={t('forms:labels.addressComponents.postCode')}
        control={FormInput}
      />
      <FormField
        isRequired={isRequired}
        name={getName('country')}
        label={t('forms:labels.addressComponents.country')}
        control={FormInput}
      />
    </React.Fragment>
  );
};
