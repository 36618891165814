import { Id, PathParams, QueryParams } from '../types';

import { DataType, DataTypeToEntity } from '../types/data';
import { ListResponse } from '../types/responses';
import { getOrganizationPath } from '../util/path';
import { get, post, put, del as baseDel } from '../client';

// CRUD actions on data types.

export interface BaseOptions<DT extends DataType> {
  dataType: DT;
  actionName?: string;
  pathParams?: PathParams;
  queryParams?: QueryParams;
}

export interface ListOptions<DT extends DataType> extends BaseOptions<DT> {}

export interface RetrieveOptions<DT extends DataType> extends BaseOptions<DT> {
  id?: Id; // ID is optional as singletons don't have IDs.
}

export interface CreateOptions<DT extends DataType> extends BaseOptions<DT> {
  data: any;
}

export interface UpdateOptions<DT extends DataType> extends BaseOptions<DT> {
  id?: Id; // ID is optional as singletons don't have IDs.
  data?: any; // Data is optional because some actions are data-less (e.g. accepting terms)
}

export interface DeleteOptions<DT extends DataType> extends BaseOptions<DT> {
  id: Id;
}

enum ActionType {
  List = 'list',
  Search = 'search',
  Retrieve = 'retrieve',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DEL',
}

interface Action {
  method: HttpMethod;
  path: string;
  type: ActionType;
}

type Actions = Record<string, Action>;

interface ActionConfig {
  path: string;
  actions: Actions;
}

const apiMethodsByType = {
  [HttpMethod.Get]: get,
  [HttpMethod.Post]: post,
  [HttpMethod.Put]: put,
  [HttpMethod.Delete]: baseDel,
};

const immutableDataActions: Actions = {
  list: {
    method: HttpMethod.Get,
    path: '',
    type: ActionType.List,
  },
  retrieve: {
    method: HttpMethod.Get,
    path: '/:id',
    type: ActionType.Retrieve,
  },
  create: {
    method: HttpMethod.Post,
    path: '',
    type: ActionType.Create,
  },
};

const commonSingletonActions: Actions = {
  retrieve: {
    method: HttpMethod.Get,
    path: '',
    type: ActionType.Retrieve,
  },
  update: {
    method: HttpMethod.Put,
    path: '',
    type: ActionType.Update,
  },
};

const commonDataActions: Actions = {
  ...immutableDataActions,
  update: {
    method: HttpMethod.Put,
    path: '/:id',
    type: ActionType.Update,
  },
  delete: {
    method: HttpMethod.Delete,
    path: '/:id',
    type: ActionType.Delete,
  },
};

const commonDataActionsWithSearch: Actions = {
  ...commonDataActions,
  search: {
    method: HttpMethod.Get,
    path: '/search',
    type: ActionType.Search,
  },
};

const dataTypeActions: Record<DataType, ActionConfig> = {
  [DataType.Account]: {
    path: getOrganizationPath('/accounts'),
    actions: {
      ...commonDataActionsWithSearch,
      listChildren: {
        method: HttpMethod.Get,
        path: '/:accountId/children',
        type: ActionType.List,
      },
    },
  },
  [DataType.AccountPlan]: {
    path: getOrganizationPath('/accountplans'),
    actions: commonDataActions,
  },
  [DataType.Aggregation]: {
    path: getOrganizationPath('/aggregations'),
    actions: commonDataActions,
  },
  [DataType.Alert]: {
    path: getOrganizationPath('/alerts'),
    actions: commonDataActions,
  },
  [DataType.AnalyticsJob]: {
    path: getOrganizationPath('/analytics/jobs'),
    actions: commonDataActions,
  },
  [DataType.Balance]: {
    path: getOrganizationPath('/balances'),
    actions: commonDataActions,
  },
  [DataType.BalanceTransaction]: {
    path: getOrganizationPath('/balances/:balanceId/transactions'),
    actions: {
      create: {
        method: HttpMethod.Post,
        path: '',
        type: ActionType.Create,
      },
      list: {
        method: HttpMethod.Get,
        path: '',
        type: ActionType.List,
      },
    },
  },
  [DataType.Bill]: {
    path: getOrganizationPath('/bills'),
    actions: {
      ...commonDataActionsWithSearch,
      getAllBillsForAccount: {
        method: HttpMethod.Get,
        path: '/accountid/:accountId',
        type: ActionType.List,
      },
      getAllBillsInPeriod: {
        method: HttpMethod.Get,
        path: '/billingperiod/:lastDateInBillingPeriod/:billingFrequency',
        type: ActionType.List,
      },
      updateBillStatus: {
        method: HttpMethod.Put,
        path: '/:id/status',
        type: ActionType.Update,
      },
      lockBill: {
        method: HttpMethod.Put,
        path: '/:id/lock',
        type: ActionType.Update,
      },
    },
  },
  [DataType.BillConfig]: {
    path: getOrganizationPath('/billconfig'),
    actions: commonSingletonActions,
  },
  [DataType.BillJob]: {
    path: getOrganizationPath('/billjobs'),
    actions: {
      ...immutableDataActions,
      generateBills: {
        method: HttpMethod.Post,
        path: '',
        type: ActionType.Create,
      },
      recalculateBills: {
        method: HttpMethod.Post,
        path: '/recalculate',
        type: ActionType.Create,
      },
      cancelBillJob: {
        method: HttpMethod.Post,
        path: '/:id/cancel',
        type: ActionType.Update,
      },
    },
  },
  [DataType.BillLineItem]: {
    path: getOrganizationPath('/lineitems'),
    actions: {
      list: {
        method: HttpMethod.Get,
        path: '',
        type: ActionType.List,
      },
    },
  },
  [DataType.Commitment]: {
    path: getOrganizationPath('/commitments'),
    actions: commonDataActions,
  },
  [DataType.CompoundAggregation]: {
    path: getOrganizationPath('/compoundaggregations'),
    actions: commonDataActions,
  },
  [DataType.Contract]: {
    path: getOrganizationPath('/contracts'),
    actions: commonDataActions,
  },
  [DataType.Counter]: {
    path: getOrganizationPath('/counters'),
    actions: commonDataActions,
  },
  [DataType.CounterAdjustment]: {
    path: getOrganizationPath('/counteradjustments'),
    actions: commonDataActions,
  },
  [DataType.CounterPricing]: {
    path: getOrganizationPath('/counterpricings'),
    actions: commonDataActions,
  },
  [DataType.CreditLineItem]: {
    path: getOrganizationPath('/bills/:billId/creditlineitems'),
    actions: commonDataActions,
  },
  [DataType.CreditReason]: {
    path: getOrganizationPath('/picklists/creditreasons'),
    actions: commonDataActions,
  },
  [DataType.Currency]: {
    path: getOrganizationPath('/picklists/currency'),
    actions: commonDataActions,
  },
  [DataType.CurrentUser]: {
    path: '/user',
    actions: {
      ...commonSingletonActions,
      acceptTerms: {
        method: HttpMethod.Put,
        path: '/accept-terms-and-conditions',
        type: ActionType.Update,
      },
    },
  },
  [DataType.Customer]: {
    path: '/customers',
    actions: commonDataActions,
  },
  [DataType.DataExplorerSavedQuery]: {
    path: getOrganizationPath('/dataexplorer/selections'),
    actions: commonDataActions,
  },
  [DataType.DebitLineItem]: {
    path: getOrganizationPath('/bills/:billId/debitlineitems'),
    actions: commonDataActions,
  },
  [DataType.DebitReason]: {
    path: getOrganizationPath('/picklists/debitreasons'),
    actions: commonDataActions,
  },
  [DataType.Destination]: {
    path: getOrganizationPath('/integrationdestinations/webhooks'),
    actions: commonDataActions,
  },
  [DataType.ExternalMapping]: {
    path: getOrganizationPath('/externalmappings'),
    actions: {
      ...commonDataActions,
      getAllMappingsForSpecificEntity: {
        method: HttpMethod.Get,
        path: `/external/:m3terEntityType/:m3terEntityId`,
        type: ActionType.List,
      },
      getAllMappingsForEntityTypeAndSystem: {
        method: HttpMethod.Get,
        path: `/allexternal/:externalSystem/:m3terEntityType`,
        type: ActionType.List,
      },
    },
  },
  [DataType.ExternalMappingConfig]: {
    path: getOrganizationPath('/externalmappingconfiguration'),
    actions: commonSingletonActions,
  },
  [DataType.Integration]: {
    path: getOrganizationPath('/integrationconfigs'),
    actions: {
      ...commonDataActions,
      getLinkedIntegrations: {
        method: HttpMethod.Get,
        path: '/entity/:entityType',
        type: ActionType.List,
      },
      reenableIntegration: {
        method: HttpMethod.Post,
        path: '/:id/enable',
        type: ActionType.Update,
      },
    },
  },
  [DataType.IntegrationCredential]: {
    path: getOrganizationPath('/integrationauth'),
    actions: {
      delete: {
        method: HttpMethod.Delete,
        path: '/:destination/:id',
        type: ActionType.Delete,
      },
      list: commonDataActions.list,
      retrieve: commonDataActions.retrieve,
    },
  },
  [DataType.Invitation]: {
    path: getOrganizationPath('/invitations'),
    actions: immutableDataActions,
  },
  [DataType.M3terEvent]: {
    path: getOrganizationPath('/events'),
    actions: commonDataActions,
  },
  [DataType.MeasurmentsDeletion]: {
    path: getOrganizationPath('/measurementsDeletions'),
    actions: immutableDataActions,
  },
  [DataType.Meter]: {
    path: getOrganizationPath('/meters'),
    actions: commonDataActions,
  },
  [DataType.NotificationRule]: {
    path: getOrganizationPath('/notifications/configurations'),
    actions: commonDataActions,
  },
  [DataType.Organization]: {
    path: '/user/organizations',
    actions: commonDataActions,
  },
  [DataType.OrganizationAdmin]: {
    path: '/organizations',
    actions: commonDataActions,
  },
  [DataType.OrganizationConfig]: {
    path: getOrganizationPath('/organizationconfig'),
    actions: commonSingletonActions,
  },
  [DataType.OrganizationCustomFields]: {
    path: getOrganizationPath('/customfields'),
    actions: commonSingletonActions,
  },
  [DataType.PermissionPolicy]: {
    path: getOrganizationPath(),
    actions: {
      create: {
        method: HttpMethod.Post,
        path: '/permissionpolicies',
        type: ActionType.Create,
      },
      delete: {
        method: HttpMethod.Delete,
        path: '/permissionpolicies/:id',
        type: ActionType.Delete,
      },
      list: {
        method: HttpMethod.Get,
        path: '/permissionpolicies',
        type: ActionType.List,
      },
      retrieve: {
        method: HttpMethod.Get,
        path: '/permissionpolicies/:id',
        type: ActionType.Retrieve,
      },
      update: {
        method: HttpMethod.Put,
        path: '/permissionpolicies/:id',
        type: ActionType.Update,
      },
      listServiceUserPermissions: {
        method: HttpMethod.Get,
        path: '/serviceusers/:id/permissions',
        type: ActionType.List,
      },
      listSupportAccessPermissions: {
        method: HttpMethod.Get,
        path: '/support-permissions',
        type: ActionType.List,
      },
      listUserPermissions: {
        method: HttpMethod.Get,
        path: '/users/:id/permissions',
        type: ActionType.List,
      },
      listUserGroupPermissions: {
        method: HttpMethod.Get,
        path: '/resourcegroups/user/:id/permissions',
        type: ActionType.List,
      },
    },
  },
  [DataType.Plan]: {
    path: getOrganizationPath('/plans'),
    actions: commonDataActions,
  },
  [DataType.PlanGroup]: {
    path: getOrganizationPath('/plangroups'),
    actions: commonDataActions,
  },
  [DataType.PlanGroupLink]: {
    path: getOrganizationPath('/plangrouplinks'),
    actions: commonDataActions,
  },
  [DataType.PlanTemplate]: {
    path: getOrganizationPath('/plantemplates'),
    actions: commonDataActions,
  },
  [DataType.Pricing]: {
    path: getOrganizationPath('/pricings'),
    actions: commonDataActions,
  },
  [DataType.Product]: {
    path: getOrganizationPath('/products'),
    actions: commonDataActions,
  },
  [DataType.ServiceUser]: {
    path: getOrganizationPath('/serviceusers'),
    actions: commonDataActions,
  },
  [DataType.StatementDefintion]: {
    path: getOrganizationPath('/statementdefinitions'),
    actions: commonDataActions,
  },
  [DataType.StatementJob]: {
    path: getOrganizationPath('/statementjobs'),
    actions: {
      ...immutableDataActions,
      cancelStatementJob: {
        method: HttpMethod.Post,
        path: '/:id/cancel',
        type: ActionType.Update,
      },
    },
  },
  [DataType.SupportAccess]: {
    path: getOrganizationPath('/support'),
    actions: commonSingletonActions,
  },
  [DataType.SupportOrganization]: {
    path: '',
    actions: {
      list: {
        method: HttpMethod.Get,
        path: '/support',
        type: ActionType.List,
      },
    },
  },
  [DataType.TransactionType]: {
    path: getOrganizationPath('/picklists/transactiontypes'),
    actions: commonDataActions,
  },
  [DataType.UserAdmin]: {
    path: '/management/users',
    actions: {
      ...immutableDataActions,
      update: {
        method: HttpMethod.Put,
        path: '/:id',
        type: ActionType.Update,
      },
    },
  },
  [DataType.UploadJob]: {
    path: getOrganizationPath('/fileuploads/measurements/jobs'),
    actions: immutableDataActions,
  },
  [DataType.User]: {
    path: getOrganizationPath('/users'),
    actions: commonDataActions,
  },
  [DataType.UserGroup]: {
    path: getOrganizationPath(),
    actions: {
      create: {
        method: HttpMethod.Post,
        path: '/resourcegroups/user',
        type: ActionType.Create,
      },
      delete: {
        method: HttpMethod.Delete,
        path: '/resourcegroups/user/:id',
        type: ActionType.Delete,
      },
      list: {
        method: HttpMethod.Get,
        path: '/resourcegroups/user',
        type: ActionType.List,
      },
      retrieve: {
        method: HttpMethod.Get,
        path: '/resourcegroups/user/:id',
        type: ActionType.Retrieve,
      },
      update: {
        method: HttpMethod.Put,
        path: '/resourcegroups/user/:id',
        type: ActionType.Update,
      },
      listByUserId: {
        method: HttpMethod.Get,
        path: '/users/:id/usergroups',
        type: ActionType.List,
      },
      addUserToUserGroup: {
        method: HttpMethod.Post,
        path: '/resourcegroups/user/:id/addresource',
        type: ActionType.Create,
      },
      removeUserFromUserGroup: {
        method: HttpMethod.Post,
        path: '/resourcegroups/user/:id/removeresource',
        type: ActionType.Update,
      },
    },
  },
};

export const isSearchable = (dataType: DataType): boolean => {
  const dataTypeActionConfig = dataTypeActions[dataType];

  return !!dataTypeActionConfig.actions.search;
};

interface PerformDataActionOptions<DT extends DataType>
  extends BaseOptions<DT> {
  actionName: string;
  data?: any;
  enforceType?: ActionType;
}

// Deliberately not exporting this as consumers should be able to use
// standard CRUD action functions or specific endpoint functions.
const performDataAction = async <DT extends DataType>({
  dataType,
  actionName,
  enforceType,
  pathParams = {},
  queryParams = {},
  data,
}: PerformDataActionOptions<DT>) => {
  const dataTypeActionConfig = dataTypeActions[dataType];

  if (!dataTypeActionConfig) {
    throw new Error(`No actions fround for for data type ${dataType}`);
  }

  const action = dataTypeActionConfig.actions[actionName];

  if (!action) {
    throw new Error(
      `Action '${actionName}' not found for data type ${dataType}`
    );
  }

  if (enforceType && action.type !== enforceType) {
    throw new Error(
      `Action '${actionName}' is of type ${action.type}, expected ${enforceType}`
    );
  }

  return apiMethodsByType[action.method]({
    path: `${dataTypeActionConfig.path}${action.path}`,
    pathParams,
    queryParams,
    body: data,
  });
};

export const list = async <DT extends DataType>({
  dataType,
  actionName = 'list',
  pathParams,
  queryParams,
}: ListOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams,
    queryParams,
  }) as Promise<ListResponse<DataTypeToEntity[DT]>>;
};

export const listAll = async <DT extends DataType>(
  options: ListOptions<DT>
) => {
  const data: Array<DataTypeToEntity[DT]> = [];

  let continueFetching = true;
  let nextToken: string | undefined;

  while (continueFetching) {
    // We can't execute the API requests in parallel because we need the nextToken from one
    // request for the next one.
    // eslint-disable-next-line no-await-in-loop
    const response = await list({
      ...options,
      queryParams: {
        ...options.queryParams,
        nextToken,
      },
    });

    data.push(...response.data);
    if (response.nextToken) {
      nextToken = response.nextToken;
    } else {
      continueFetching = false;
    }
  }

  return { data } as ListResponse<DataTypeToEntity[DT]>;
};

export const retrieve = async <DT extends DataType>({
  dataType,
  actionName = 'retrieve',
  id,
  pathParams,
  queryParams,
}: RetrieveOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams: { ...pathParams, id },
    queryParams,
  }) as Promise<DataTypeToEntity[DT]>;
};

export const create = async <DT extends DataType>({
  dataType,
  actionName = 'create',
  pathParams,
  queryParams,
  data,
}: CreateOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams,
    queryParams,
    data,
  }) as Promise<DataTypeToEntity[DT]>;
};

export const update = async <DT extends DataType>({
  dataType,
  actionName = 'update',
  id,
  pathParams,
  queryParams,
  data,
}: UpdateOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams: { ...pathParams, id },
    queryParams,
    data,
  }) as Promise<DataTypeToEntity[DT]>;
};

export const del = async <DT extends DataType>({
  dataType,
  actionName = 'delete',
  id,
  pathParams,
  queryParams,
}: DeleteOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams: { ...pathParams, id },
    queryParams,
  }) as Promise<DataTypeToEntity[DT]>;
};
