import React from 'react';

import { SettingsIcon, ShieldIcon } from 'lucide-react';

import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';

export interface SettingsNavigationProps {}

export const SettingsNavigation: React.FC<SettingsNavigationProps> = () => {
  const { t } = useTranslation();

  return (
    <Navigation>
      <NamedNavLink name="settings.organization" icon={<SettingsIcon />}>
        {t('common:organization')}
      </NamedNavLink>
      <NamedNavLink name="settings.access" icon={<ShieldIcon />}>
        {t('common:access')}
      </NamedNavLink>
    </Navigation>
  );
};
