import React from 'react';

import { Contract } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { UserAdminForm } from '@/components/features/admin/users/UserAdminForm';

export const UserAdminEditRoute: React.FC = () => (
  <CrudEdit<Contract> form={UserAdminForm} />
);
