import React, { useMemo } from 'react';

import { DataType, OrganizationAdmin, UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DropdownButton, DropdownButtonAction } from '@m3ter-com/ui-components';

import useOrgUserAdmin from '@/hooks/features/admin/useOrgUserAdmin';
import useResendTempPassword from '@/hooks/features/admin/useResendTempPassword';
import { OrganizationAdminPermissionsModal } from '@/components/features/admin/organizations/OrganizationAdminPermissionsModal';
import { OrganizationDetails } from '@/components/features/organization/OrganizationDetails';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { OrganizationAdminResendPasswordModal } from '@/components/features/admin/organizations/OrganizationAdminResendPasswordModal';
import {
  ColumnDefinition,
  EntityMultiSelectModal,
} from '@/components/common/data/EntitySelectModal';

interface OrganizationAdminDetailsProps {
  data: OrganizationAdmin;
}

const addUsersListSearchFields = ['firstName', 'lastName'];

const emptyArray = new Array<any>();

export const OrganizationAdminDetails: React.FC<
  OrganizationAdminDetailsProps
> = ({ data: org }) => {
  const { t } = useTranslation();
  const {
    isOrgUserModalOpen,
    isUserPermissionsModalOpen,
    onAddPermissionToUser,
    onAddUserToOrg,
    onCloseOrgUserModal,
    onCloseUserPermissionsModal,
    onOpenOrgUserModal,
    onOpenUserPermissionsModal,
  } = useOrgUserAdmin(org);

  const {
    onResendTempPassword,
    onOpenResendPasswordModal,
    onCloseResendPasswordModal,
    isResendPasswordModalOpen,
  } = useResendTempPassword(org.id);

  const actions = useMemo<Array<DropdownButtonAction>>(
    () => [
      {
        key: 'edit',
        label: t('common:edit'),
        as: CrudEditLink,
        addReturnPath: true,
        dataType: DataType.OrganizationAdmin,
        id: org.id,
        intent: 'primary',
      },
      {
        key: 'open-add-user-modal',
        label: t('features:admin.addUser'),
        onClick: onOpenOrgUserModal,
      },
      {
        name: t('features:admin.addUserPermissionPolicies'),
        key: 'open-add-user-permission-modal',
        label: t('features:admin.addUserPermissionPolicies'),
        onClick: onOpenUserPermissionsModal,
      },
      {
        key: 'open-resend-temp-password-modal',
        label: t('features:admin.resendUsersTempPassword'),
        onClick: onOpenResendPasswordModal,
      },
    ],
    [
      onOpenOrgUserModal,
      onOpenResendPasswordModal,
      onOpenUserPermissionsModal,
      org.id,
      t,
    ]
  );

  const addUsersModalColumns = useMemo<Array<ColumnDefinition<UserAdmin>>>(
    () => [
      {
        id: 'name',
        accessor: (item: UserAdmin) => `${item.firstName} ${item.lastName}`,
        header: t('forms:labels.name'),
      },
      {
        id: 'firstName',
        accessor: 'firstName',
        header: t('forms:labels.lastName'),
        defaultHidden: true,
      },
      {
        id: 'lastName',
        accessor: 'lastName',
        header: t('forms:labels.lastName'),
        defaultHidden: true,
      },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <OrganizationDetails
        showCustomer
        org={org}
        adminActions={
          <DropdownButton actions={actions} maxButtons={1} size="sm" />
        }
      />
      <OrganizationAdminResendPasswordModal
        isOpen={isResendPasswordModalOpen}
        onClose={onCloseResendPasswordModal}
        onSubmit={onResendTempPassword}
      />
      <OrganizationAdminPermissionsModal
        isOpen={isUserPermissionsModalOpen}
        onClose={onCloseUserPermissionsModal}
        onSubmit={onAddPermissionToUser}
      />
      <EntityMultiSelectModal<UserAdmin>
        columns={addUsersModalColumns}
        dataType={DataType.UserAdmin}
        isOpen={isOrgUserModalOpen}
        onClose={onCloseOrgUserModal}
        onConfirm={onAddUserToOrg}
        searchFields={addUsersListSearchFields}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
