import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const popover: ComponentMultiStyleConfig = {
  parts: ['popper', 'content', 'header', 'body', 'footer', 'closeButton'],
  baseStyle: {
    popper: {
      zIndex: 'popover',
    },
  },
};

export default popover;
