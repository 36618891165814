import { skipToken } from '@tanstack/react-query';

import {
  getLatestIntegrationRun,
  Id,
  IntegrationRunViewEntityTypes,
} from '@m3ter-com/m3ter-api';

import useOrg from '@/hooks/data/crud/useOrg';
import useAppQuery from '@/hooks/data/useAppQuery';

// id is optional here as some integration runs are going to be for linked entities, if we don't have that we want to
// disable the itemAction
const useIntegrationRunView = (
  entityType: IntegrationRunViewEntityTypes,
  id?: Id
) => {
  const { currentOrgId: organizationId } = useOrg();

  const { data: latestIntegrationRunData } = useAppQuery({
    queryFn: id
      ? () => getLatestIntegrationRun(organizationId, entityType, id)
      : skipToken,
    queryKey: ['lastestIntegrationRun', organizationId, entityType, id],
  });

  return { latestIntegrationRunData };
};

export default useIntegrationRunView;
