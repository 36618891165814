import { SystemStyleObject, ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['list', 'item'];

const baseStyleList: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  top: 2,
  left: 2,
  right: 2,
  listStyle: 'none',
  zIndex: 'banner',
  pointerEvents: 'none',
};

const baseStyleItem: SystemStyleObject = {
  marginBottom: 3,
  minWidth: 300,
  maxWidth: 600,
};

const notificationsList: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    list: baseStyleList,
    item: baseStyleItem,
  },
};

export default notificationsList;
