import React from 'react';

import { useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import { LogOutIcon, UserCog2Icon, WrenchIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { HeaderActions, HeaderActionButton } from '@m3ter-com/ui-components';

import { selectUser } from '@/store/app/auth/auth';
import { NamedLink } from '@/components/common/navigation/NamedLink';

import { ProfileEditModal } from './ProfileEditModal';
import { ColorModeSwitcher } from './ColorModeSwitcher';

export interface UserActionsProps {
  onLogOutClick: () => void;
  isM3terAdminEnabled: boolean;
}

export const UserActions: React.FC<UserActionsProps> = ({
  onLogOutClick,
  isM3terAdminEnabled,
}) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const {
    isOpen: isEditProfileModalOpen,
    onOpen: onEditProfileModalOpen,
    onClose: onEditProfileModalClose,
  } = useDisclosure();

  return user ? (
    <React.Fragment>
      <HeaderActions
        fullName={`${user.firstName} ${user.lastName}`}
        email={user.email}
      >
        <ColorModeSwitcher />
        <HeaderActionButton
          leftIcon={<UserCog2Icon size={16} />}
          onClick={onEditProfileModalOpen}
        >
          {t('common:editProfile')}
        </HeaderActionButton>
        {isM3terAdminEnabled && (
          <HeaderActionButton
            leftIcon={<WrenchIcon size={16} />}
            as={NamedLink}
            target="_blank"
            name="admin"
            data-testid="user-menu-admin-link"
          >
            {t('common:admin')}
          </HeaderActionButton>
        )}
        <HeaderActionButton
          leftIcon={<LogOutIcon size={16} />}
          onClick={onLogOutClick}
          data-testid="user-menu-logout"
        >
          {t('common:logOut')}
        </HeaderActionButton>
      </HeaderActions>
      {/* Only render the modal when opening to avoid loading user details unnecessarily */}
      {isEditProfileModalOpen && (
        <ProfileEditModal isOpen onClose={onEditProfileModalClose} />
      )}
    </React.Fragment>
  ) : null;
};
