import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['trigger', 'header', 'content', 'button'];

const headerActions: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    trigger: {
      color: 'white',
    },
    header: {
      padding: 4,
      bg: 'chakra-body-bg',
      textAlign: 'center',
    },
    body: {
      padding: 0,
    },
    button: {
      width: '100%',
      paddingX: 6,
      justifyContent: 'start',
      bg: 'none',
      borderRadius: 0,
      _hover: {
        bg: 'chakra-body-bg',
      },
    },
  },
};

export default headerActions;
