import React from 'react';

import { useColorMode } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from 'lucide-react';

import { HeaderActionButton } from '@m3ter-com/ui-components';

export const ColorModeSwitcher: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const Icon = colorMode === 'light' ? MoonIcon : SunIcon;

  return (
    <HeaderActionButton onClick={toggleColorMode} leftIcon={<Icon size={16} />}>
      {colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
    </HeaderActionButton>
  );
};
