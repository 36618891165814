import React, { useEffect } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useNamedNavigate from '@/hooks/navigation/useNamedNavigate';
import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper';
import { AccountsNavigation } from '@/components/common/navigation/sections/AccountsNavigation';
import { RecentAccounts } from '@/components/common/navigation/RecentAccounts';

import setupAccountsRoute from './accounts';
import setupDataExplorerRoute from './data-explorer';
import setupReportsRoute from './reports';

const AccountRedirect: React.FC = () => {
  const params = useParams();
  const navigate = useNamedNavigate();

  useEffect(() => {
    navigate(getCrudRouteName(DataType.Account, CrudRouteType.Details), params);
  }, [params, navigate]);

  return null;
};

export default (): NamedRoute => ({
  path: 'accounts',
  name: 'accounts-root', // Can't overload `accounts` from CRUD route.
  element: (
    <RouteWrapper
      navigation={<AccountsNavigation />}
      navigationActions={<RecentAccounts />}
    />
  ),
  children: [
    { index: true, element: <Navigate replace to="accounts" /> },
    setupAccountsRoute(),
    setupDataExplorerRoute(),
    setupReportsRoute(),
    // Redirect from old `/accounts/:accountId` to `/accounts/accounts/:accountId`
    {
      path: ':accountId',
      element: <AccountRedirect />,
    },
  ],
});
