import type { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['wrapper', 'header', 'navigation', 'main'];

const HEADER_HEIGHT = '60px';

const appLayout: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    wrapper: {
      minHeight: '100vh',
      display: 'grid',
      gridTemplateColumns: 'auto minmax(0, 1fr)',
      gridTemplateRows: `${HEADER_HEIGHT} 1fr`,
      gridTemplateAreas: "'header header' 'nav main'",
    },
    header: {
      gridArea: 'header',
      height: HEADER_HEIGHT,
      position: 'sticky',
      top: 0,
      zIndex: 'sticky',
    },
    navigation: {
      gridArea: 'nav',
      position: 'sticky',
      top: HEADER_HEIGHT,
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      flexShrink: 0,
      overflowY: 'auto',
    },
    main: {
      gridArea: 'main',
      padding: 4,
    },
  },
};

export default appLayout;
