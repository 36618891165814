import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import { UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormSwitch,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import userAdminSchema from '@/validation/userAdmin';

export interface UserAdminFormProps extends BaseFormProps<UserAdmin> {}

export interface UserAdminFormExtraData {
  isEdit: boolean;
}

const defaultInitialValues: Partial<UserAdmin> = {};

export const UserAdminSupportUserField: React.FC = () => {
  const { t } = useTranslation();
  const email: string | undefined = useWatch({ name: 'email' });
  const isInternalM3terUser = !!email?.endsWith('@m3ter.com');

  return (
    <FormField
      isRequired
      isDisabled={!isInternalM3terUser}
      name="supportUser"
      label={t('common:supportUser')}
      helpText={t('forms:helpText.userAdminSupportUser')}
      control={FormSwitch}
    />
  );
};

export const UserAdminForm: React.FC<UserAdminFormProps> = ({
  onSave,
  onCancel,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:user');

  const extraValidationData = useMemo(() => ({ isEdit }), [isEdit]);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={userAdminSchema}
      extraValidationData={extraValidationData}
    >
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <FormField
            isRequired
            name="firstName"
            label={t('forms:labels.firstName')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="lastName"
            label={t('forms:labels.lastName')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="email"
            label={t('forms:labels.emailAddress')}
            control={FormInput}
            type="email"
          />
          {!isEdit && (
            <FormField
              isRequired
              name="tempPassword"
              label={t('forms:labels.tempPassword')}
              control={FormInput}
              type="password"
              helpText={t('forms:helpText.userAdminTemporaryPassword')}
            />
          )}
          <FormField
            name="contactNumber"
            label={t('forms:labels.contactNumber')}
            control={FormInput}
            type="number"
          />
          <UserAdminSupportUserField />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
