import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyBillingExplorerRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillingExplorer" */ '@/components/routes/analytics/data-explorer/BillingExplorerRoute'
  ).then((module) => ({ default: module.BillingExplorerRoute }))
);

export default (): NamedRoute => ({
  path: 'data-explorer',
  name: 'billing.data-explorer',
  element: <LazyBillingExplorerRoute />,
});
