import React from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { DataType, UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';

interface UserAdminDetailsProps {
  data: UserAdmin;
}

export const UserAdminDetails: React.FC<UserAdminDetailsProps> = ({
  data: userAdmin,
}) => {
  const { t } = useTranslation();
  const fullName = `${userAdmin.firstName} ${userAdmin.lastName}`;

  return (
    <React.Fragment>
      <BreadcrumbItem title={fullName} />
      <DetailsCard
        data={userAdmin}
        dataType={DataType.UserAdmin}
        showEditButton
        details={
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue label={t('forms:labels.name')} value={fullName} />
            <KeyValue
              label={t('forms:labels.emailAddress')}
              value={userAdmin.email}
            />
            <KeyValue
              label={t('forms:labels.contactNumber')}
              value={userAdmin.contactNumber}
            />
            <KeyValue
              label={t('common:supportUser')}
              value={userAdmin.supportUser ? t('common:yes') : t('common:no')}
            />
          </SimpleGrid>
        }
      />
    </React.Fragment>
  );
};
