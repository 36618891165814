import { call, put, select, StrictEffect } from 'redux-saga/effects';
import i18next from 'i18next';
import { formatInTimeZone } from 'date-fns-tz';

import {
  AnalyticsJob,
  AnalyticsJobStatus,
  AnalyticsJobType,
  DataExplorerUsageDataRequestBody,
  DataExplorerUsageMeterDimensionsRequestBody,
  DataExplorerUsageMeterDimensionsResponse,
  DataExplorerUsageMetersResponse,
  PathParams,
  QueryParams,
  getDataExplorerUsageMeterDimensionValues,
  getDataExplorerUsageMeters,
  getAnalyticsJobWithDownloadUrl,
} from '@m3ter-com/m3ter-api';
import { timeFormatKey } from '@m3ter-com/console-core/utils';

import {
  DataExplorerDataType,
  DataExplorerMeterDimensionDatum,
  DataExplorerUsageDataPivotTableRow,
  DataExplorerUsageDataTableRow,
} from '@/types/data';

import { uniq } from '@/util/array';
import { extractAnalyticsJobError, extractError } from '@/util/error';
import { selectOrgTimezone } from '@/store/app/bootstrap/bootstrap';
import { getAnalyticsResult } from '@/store/features/analytics/analyticsJobs.saga';
import {
  createSelectByDataExplorerDataType,
  loadDataExplorerDataFailure,
  loadDataExplorerDataSuccess,
  loadDataExplorerExportUrlFailure,
  loadDataExplorerExportUrlStarted,
  loadDataExplorerExportUrlSuccess,
  DataExplorerState,
} from '@/store/features/analytics/data-explorer/dataExplorer';

function* dimensionValuesLoader(
  organizationId: string,
  body: DataExplorerUsageMeterDimensionsRequestBody
): Generator<StrictEffect, DataExplorerMeterDimensionDatum | string, any> {
  try {
    const response: DataExplorerUsageMeterDimensionsResponse = yield call(
      getDataExplorerUsageMeterDimensionValues,
      organizationId,
      body
    );
    const uniqueAttributeNames = uniq(
      response.dimensionValues.map((val) => val.dimensionAttribute)
    );
    const attributes: DataExplorerMeterDimensionDatum['attributes'] =
      uniqueAttributeNames.map((attributeName) => ({
        attributeName,
        filterValues: uniq(
          response.dimensionValues
            .filter((val) => val.dimensionAttribute === attributeName)
            .map((val) => val.dimensionValue)
        ),
      }));
    return {
      attributes,
      dimensionName: body.dimensionName,
    };
  } catch {
    return body.dimensionName;
  }
}

export function* meterDimensionsLoader(
  organizationId: string,
  body: DataExplorerUsageMeterDimensionsRequestBody
): Generator<StrictEffect, void, any> {
  try {
    // Grab the current meters data from the dataExplorer slice
    const metersStoreSelector = yield call(
      createSelectByDataExplorerDataType,
      DataExplorerDataType.UsageMeters
    );
    const metersStore: DataExplorerState[DataExplorerDataType.UsageMeters] =
      yield select(metersStoreSelector);
    const allMeters = metersStore?.data?.values || [];
    const selectedMeters =
      body.meterCodes.length === 0
        ? allMeters
        : allMeters.filter((meter) =>
            body.meterCodes.includes(meter.meterCode)
          );
    // If we are currently loading new meters data or we can't find the data for the provided
    // meter codes, we can't attempt to load dimensions, get out early
    if (metersStore?.isLoadingData || selectedMeters.length === 0) {
      yield put(
        loadDataExplorerDataSuccess(DataExplorerDataType.UsageMeterDimensions, {
          failures: [],
          values: [],
        })
      );
      return;
    }

    // Meters are likely to share dimensions & dimension attributes.
    // So that we can make the minimum number of network requests, we map each unique dimension name
    // to a collated set of dimension attributes.
    // We then query once for each unique dimension, against all of the selected meters, and the API returns
    // us all the values that have been used for each of the attributes.
    const allDimensions = selectedMeters.flatMap((meter) => meter.dimensions);
    const uniqueDimensionNames = uniq(
      allDimensions.map((dimension) => dimension.dimensionName)
    );
    const dimensionFailures = new Array<string>();
    const dimensionsData = new Array<DataExplorerMeterDimensionDatum>();
    for (let i = 0; i < uniqueDimensionNames.length; i += 1) {
      const dimensionName = uniqueDimensionNames[i];
      const uniqueDimensionAttributes = uniq(
        allDimensions
          .filter((dimension) => dimension.dimensionName === dimensionName)
          .flatMap((dimension) => dimension.dimensionAttributes)
      );
      const dimensionRequestBody: DataExplorerUsageMeterDimensionsRequestBody =
        {
          attributes: uniqueDimensionAttributes,
          dimensionName,
          endDate: body.endDate,
          meterCodes: body.meterCodes,
          startDate: body.startDate,
        };
      const dimensionResponse: DataExplorerMeterDimensionDatum | string =
        yield call(dimensionValuesLoader, organizationId, dimensionRequestBody);
      if (typeof dimensionResponse === 'string') {
        dimensionFailures.push(dimensionResponse);
      } else {
        dimensionsData.push(dimensionResponse);
      }
    }

    yield put(
      loadDataExplorerDataSuccess(DataExplorerDataType.UsageMeterDimensions, {
        failures: dimensionFailures,
        values: dimensionsData,
      })
    );
  } catch (error) {
    yield put(
      loadDataExplorerDataFailure(
        DataExplorerDataType.UsageMeterDimensions,
        extractError(error)
      )
    );
  }
}

export function* metersLoader(
  organizationId: string,
  _body: undefined,
  _pathParams?: PathParams,
  queryParams?: QueryParams
): Generator<StrictEffect, void, any> {
  try {
    const response: DataExplorerUsageMetersResponse = yield call(
      getDataExplorerUsageMeters,
      organizationId,
      queryParams
    );

    yield put(
      loadDataExplorerDataSuccess(DataExplorerDataType.UsageMeters, response)
    );
  } catch (error) {
    yield put(
      loadDataExplorerDataFailure(
        DataExplorerDataType.UsageMeters,
        extractError(error)
      )
    );
  }
}

export function* usageDataLoader(
  organizationId: string,
  body: DataExplorerUsageDataRequestBody,
  _pathParams?: PathParams,
  _queryParams?: QueryParams,
  loadExportUrl?: boolean
): Generator<StrictEffect, void, any> {
  let analyticsJobId: string | undefined;
  let canLoadExportUrl = false;

  try {
    const analyticsJob: AnalyticsJob<AnalyticsJobType.UsageData> = yield call(
      getAnalyticsResult,
      AnalyticsJobType.UsageData,
      body
    );
    if (
      analyticsJob.status !== AnalyticsJobStatus.Succeeded ||
      !analyticsJob.data
    ) {
      throw new Error('Failed to load usage data.');
    }

    analyticsJobId = analyticsJob.id;
    canLoadExportUrl = !!analyticsJob.data?.values?.length;
    const orgTimeZone = yield select(selectOrgTimezone);
    const tableColumnKeys = new Set<string>();
    const tableColumns = new Array<{ key: string; title: string }>();
    const tableData = new Array<DataExplorerUsageDataTableRow>();
    const pivotData = new Array<DataExplorerUsageDataPivotTableRow>();
    // If aggregations have been applied to the query, we expect the API
    // not to give us UIDs for each row of usage data because each row
    // actually represents multiple submissions.
    // If no aggregations have been applied, we expect to recieve a UID
    // for each row
    const isAggregatedQuery = body.measures.some(
      (measure) => measure.aggregations.length > 0
    );
    if (!isAggregatedQuery) {
      tableColumns.push({ key: 'uid', title: 'UID' });
    }

    analyticsJob.data.values.forEach((responseValue, responseValueIndex) => {
      const rowMeasureEntries = Object.entries(responseValue.measures).reduce(
        (result, [measureName, measureSet]) => {
          Object.entries(measureSet).forEach(
            ([aggregationName, measureValue]) => {
              // For each measure and each aggregation on that measure,
              // we want to do three things:
              // 1. Add the key for each measure-aggregation combination
              // to our set of table column keys, if it hasn't already
              // been added. This means we can ensure we are only adding
              // each table column once.
              // 2. If we are adding one of these keys to the set for the
              // first time, we add that same key and a user-friendly variation
              // to the tableColumns array. This allows us to know what properties
              // we have in our data when it comes to rendering a table to show the
              // data, since we don't know at compile-time what the response for any given
              // usage request is going to look like.
              // 3. Add that same key and the corresponding value for this measure-aggregation
              // combinatio to the entries array so we can build an object out of it.
              const key = `${measureName}_${aggregationName}`;
              if (!tableColumnKeys.has(key)) {
                tableColumnKeys.add(key);
                const title = `${measureName} (${aggregationName})`;
                tableColumns.push({ key, title });
              }
              result.push([key, measureValue]);
            }
          );
          return result;
        },
        new Array<Array<string | number>>()
      );

      const rowDimensionEntries = Object.entries(
        responseValue.dimensions || {}
      ).reduce((result, [dimensionName, dimensionSet]) => {
        Object.entries(dimensionSet).forEach(([attributeName, filterValue]) => {
          const key = `${dimensionName}_${attributeName}`;
          if (responseValueIndex === 0) {
            const title = `${dimensionName} (${attributeName})`;
            tableColumns.push({ key, title });
          }

          result.push([key, filterValue]);
        });
        return result;
      }, new Array<Array<string | number>>());

      const { meterCode, meterName, timestamp, uid = '' } = responseValue;
      const timestampDateInstance = new Date(timestamp);
      const year = timestampDateInstance.getFullYear();
      const monthNumber = timestampDateInstance.getMonth() + 1;
      const monthName = i18next.t(
        `common:months.${timestampDateInstance.getMonth()}`
      );
      const monthString = `${monthNumber}-${monthName}`;
      const tableRow = Object.fromEntries([
        ['id', `row-${responseValueIndex}`],
        ['date', timestamp],
        ['year', year],
        ['month', monthString],
        ['meterCode', meterCode],
        ['meterName', meterName],
        ...rowMeasureEntries,
        ...rowDimensionEntries,
      ]);
      if (!isAggregatedQuery) {
        tableRow.uid = uid;
      }
      tableData.push(tableRow);

      const date = timestampDateInstance.getDate();
      const time = formatInTimeZone(
        timestampDateInstance,
        orgTimeZone,
        timeFormatKey
      );
      const pivotRow = Object.fromEntries([
        ['meterCode', meterCode],
        ['meterName', meterName],
        ['year', year],
        ['month', monthString],
        ['date', date],
        ['time', time],
        ...rowMeasureEntries,
        ...rowDimensionEntries,
      ]);
      if (!isAggregatedQuery) {
        pivotRow.uid = uid;
      }
      pivotData.push(pivotRow);
    });

    yield put(
      loadDataExplorerDataSuccess(DataExplorerDataType.UsageData, {
        tableColumns,
        tableData,
        pivotData,
      })
    );
  } catch (error) {
    let errorMessage: string;
    ({ analyticsJobId, canLoadExportUrl, errorMessage } =
      extractAnalyticsJobError(error));

    yield put(
      loadDataExplorerDataFailure(DataExplorerDataType.UsageData, {
        code: undefined,
        message: errorMessage,
      })
    );
  }

  if (loadExportUrl && analyticsJobId && canLoadExportUrl) {
    try {
      yield put(
        loadDataExplorerExportUrlStarted(DataExplorerDataType.UsageData)
      );
      const exportAnalyticsJob: AnalyticsJob<AnalyticsJobType.UsageData> =
        yield call(
          getAnalyticsJobWithDownloadUrl,
          organizationId,
          analyticsJobId
        );
      if (!exportAnalyticsJob.downloadUrl) {
        throw new Error('Failed to load usage export URL.');
      }
      yield put(
        loadDataExplorerExportUrlSuccess(
          DataExplorerDataType.UsageData,
          exportAnalyticsJob.downloadUrl!
        )
      );
    } catch (error) {
      yield put(
        loadDataExplorerExportUrlFailure(
          DataExplorerDataType.UsageData,
          extractError(error)
        )
      );
    }
  }
}
