import { call, put, StrictEffect } from 'redux-saga/effects';

import {
  AnalyticsJob,
  AnalyticsJobStatus,
  AnalyticsJobType,
  DataExplorerBillingDataRequestBody,
  PathParams,
  QueryParams,
  getAnalyticsJobWithDownloadUrl,
} from '@m3ter-com/m3ter-api';

import {
  DataExplorerBillingDataPivotTableRow,
  DataExplorerBillingDataTableRow,
  DataExplorerDataType,
} from '@/types/data';

import { extractAnalyticsJobError, extractError } from '@/util/error';
import { getAnalyticsResult } from '@/store/features/analytics/analyticsJobs.saga';
import {
  loadDataExplorerDataFailure,
  loadDataExplorerDataSuccess,
  loadDataExplorerExportUrlFailure,
  loadDataExplorerExportUrlStarted,
  loadDataExplorerExportUrlSuccess,
} from '@/store/features/analytics/data-explorer/dataExplorer';

export function* billingDataLoader(
  organizationId: string,
  body: DataExplorerBillingDataRequestBody,
  _pathParams?: PathParams,
  _queryParams?: QueryParams,
  loadExportUrl?: boolean
): Generator<StrictEffect, void, any> {
  let analyticsJobId: string | undefined;
  let canLoadExportUrl = false;

  try {
    const analyticsJob: AnalyticsJob<AnalyticsJobType.BillingData> = yield call(
      getAnalyticsResult,
      AnalyticsJobType.BillingData,
      body
    );
    if (
      analyticsJob.status !== AnalyticsJobStatus.Succeeded ||
      !analyticsJob.data
    ) {
      throw new Error('Failed to load billing data.');
    }

    analyticsJobId = analyticsJob.id;
    canLoadExportUrl = !!analyticsJob.data?.values?.length;
    const tableData = new Array<DataExplorerBillingDataTableRow>();
    const pivotData = new Array<DataExplorerBillingDataPivotTableRow>();
    analyticsJob!.data.values.forEach((responseValue, responseValueIndex) => {
      tableData.push({
        id: `row-${responseValueIndex}`,
        account:
          responseValue.dimensions.accountName ||
          responseValue.dimensions.account,
        billDate: responseValue.billDetails.billDate,
        billFrequency: responseValue.billDetails.billingFrequency,
        conversionRate: responseValue.measures.conversionRate,
        convertedCurrency: responseValue.billDetails.billCurrency,
        convertedSubtotal: responseValue.measures.convertedSubtotal,
        currency: responseValue.dimensions.subtotalUnit,
        description: responseValue.dimensions.description,
        endDate: responseValue.billDetails.endDate,
        lineItemType: responseValue.dimensions.lineItemType,
        locked: responseValue.billDetails.locked === 'True',
        productName: responseValue.dimensions.productName,
        quantity: responseValue.measures.quantity,
        startDate: responseValue.billDetails.startDate,
        subtotal: responseValue.measures.subtotal,
        status: responseValue.billDetails.status,
        unit: responseValue.dimensions.quantityUnit,
        updatedDate: responseValue.updatedDate,
      });

      const { month } = responseValue.billDetails;
      const monthNumber = new Date(`2000 ${month}`).getMonth() + 1;
      pivotData.push({
        account:
          responseValue.dimensions.accountName ||
          responseValue.dimensions.account,
        billDate: responseValue.billDetails.billDate,
        billFrequency: responseValue.billDetails.billingFrequency,
        conversionRate: responseValue.measures.conversionRate,
        convertedCurrency: responseValue.billDetails.billCurrency,
        convertedSubtotal: responseValue.measures.convertedSubtotal,
        currency: responseValue.dimensions.subtotalUnit,
        day: responseValue.billDetails.day,
        description: responseValue.dimensions.description,
        endDate: responseValue.billDetails.endDate,
        lineItemType: responseValue.dimensions.lineItemType,
        locked: responseValue.billDetails.locked === 'True',
        month: `${monthNumber}-${month}`,
        productName: responseValue.dimensions.productName,
        quantity: responseValue.measures.quantity,
        startDate: responseValue.billDetails.startDate,
        subtotal: responseValue.measures.subtotal,
        status: responseValue.billDetails.status,
        unit: responseValue.dimensions.quantityUnit,
        updatedDate: responseValue.updatedDate,
        week: responseValue.billDetails.week,
        year: responseValue.billDetails.year,
        yearMonth: responseValue.billDetails.yearMonth,
      });
    });

    yield put(
      loadDataExplorerDataSuccess(DataExplorerDataType.BillingData, {
        tableData,
        pivotData,
      })
    );
  } catch (error) {
    let errorMessage: string;
    ({ analyticsJobId, canLoadExportUrl, errorMessage } =
      extractAnalyticsJobError(error));

    yield put(
      loadDataExplorerDataFailure(DataExplorerDataType.BillingData, {
        code: undefined,
        message: errorMessage,
      })
    );
  }

  if (loadExportUrl && analyticsJobId && canLoadExportUrl) {
    try {
      yield put(
        loadDataExplorerExportUrlStarted(DataExplorerDataType.BillingData)
      );
      const exportAnalyticsJob: AnalyticsJob<AnalyticsJobType.BillingData> =
        yield call(
          getAnalyticsJobWithDownloadUrl,
          organizationId,
          analyticsJobId
        );
      if (!exportAnalyticsJob.downloadUrl) {
        throw new Error('Failed to load billing data export URL.');
      }
      yield put(
        loadDataExplorerExportUrlSuccess(
          DataExplorerDataType.BillingData,
          exportAnalyticsJob.downloadUrl!
        )
      );
    } catch (error) {
      yield put(
        loadDataExplorerExportUrlFailure(
          DataExplorerDataType.BillingData,
          extractError(error)
        )
      );
    }
  }
}
