import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  BillingEntity,
  DateTimeISOString,
  Id,
  EndBillingEntitiesDataType,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';

export interface EndBillingEntitiesState {
  isSubmitting: boolean;
  error?: AppError;
}

export interface EndBillingEntitiesPayload {
  id: Id;
  dataType: EndBillingEntitiesDataType;
  endDate: DateTimeISOString;
  billingEntities: Array<BillingEntity>;
  applyToChildren?: boolean;
}

export type EndBillingEntitiesAction = PayloadAction<
  EndBillingEntitiesPayload,
  string,
  RequestMeta
>;

export type EndBillingEntitiesSuccessAction = PayloadAction<undefined>;

export type EndBillingEntitiesFailureAction = PayloadAction<
  undefined,
  string,
  CompletionMeta | undefined,
  AppError
>;

const initialState: EndBillingEntitiesState = {
  isSubmitting: false,
};

const name = 'features/accounts/endBillingEntities';

const endBillingEntitiesSlice = createSlice({
  name,
  initialState,
  reducers: {
    endBillingEntities: {
      reducer: (
        state: EndBillingEntitiesState,
        _action: EndBillingEntitiesAction
      ) => {
        state.isSubmitting = true;
        state.error = undefined;
      },
      prepare: (
        id: Id,
        dataType: DataType.Account | DataType.Contract,
        endDate: DateTimeISOString,
        billingEntities: Array<BillingEntity>,
        applyToChildren?: boolean,
        failureNotification?: NotificationDefinition
      ) => ({
        payload: { id, dataType, endDate, billingEntities, applyToChildren },
        meta: {
          onFailure: { notification: failureNotification },
        },
      }),
    },
    endBillingEntitiesSuccess: {
      reducer: (
        state: EndBillingEntitiesState,
        _action: EndBillingEntitiesSuccessAction
      ) => {
        state.isSubmitting = false;
      },
      prepare: () => ({
        payload: undefined,
      }),
    },
    endBillingEntitiesFailure: {
      reducer: (
        state: EndBillingEntitiesState,
        action: EndBillingEntitiesFailureAction
      ) => {
        state.isSubmitting = false;
        state.error = action.error;
      },
      prepare: (error: AppError, meta?: CompletionMeta) => ({
        payload: undefined,
        error,
        meta,
      }),
    },
  },
});

export const {
  endBillingEntities,
  endBillingEntitiesFailure,
  endBillingEntitiesSuccess,
} = endBillingEntitiesSlice.actions;

const selectEndBillingEntitiesState = (state: {
  features: { accounts: { endBillingEntities: EndBillingEntitiesState } };
}): EndBillingEntitiesState => state.features.accounts.endBillingEntities;

export const selectIsSubmitting = createSelector(
  selectEndBillingEntitiesState,
  (state) => state.isSubmitting
);

export const selectError = createSelector(
  selectEndBillingEntitiesState,
  (state) => state?.error
);

export default endBillingEntitiesSlice.reducer;
