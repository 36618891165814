export enum AggregationType {
  Sum = 'SUM',
  Min = 'MIN',
  Max = 'MAX',
  Count = 'COUNT',
  Latest = 'LATEST',
  Mean = 'MEAN',
  Unique = 'UNIQUE',
}

export enum AlertStatus {
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
}

export enum AlertSeverity {
  Error = 'ERROR',
  Info = 'INFO',
  Warn = 'WARN',
}

export enum AlertType {
  Billing = 'BILLING',
  Etl = 'ETL',
}

export enum AnalyticsJobStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
}

export enum AnalyticsJobTimePeriodType {
  Custom = 'CUSTOM',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  WeekToDate = 'WEEK_TO_DATE',
  MonthToDate = 'MONTH_TO_DATE',
  YearToDate = 'YEAR_TO_DATE',
  PreviousWeek = 'PREVIOUS_WEEK',
  PreviousMonth = 'PREVIOUS_MONTH',
  PreviousQuarter = 'PREVIOUS_QUARTER',
  PreviousYear = 'PREVIOUS_YEAR',
  LastSevenDays = 'LAST_7_DAYS',
  LastThirtyDays = 'LAST_30_DAYS',
  LastThirtyFiveDays = 'LAST_35_DAYS',
  LastNinetyDays = 'LAST_90_DAYS',
  LastOneHundredAndTwentyDays = 'LAST_120_DAYS',
  LastYear = 'LAST_YEAR',
}

export enum AnalyticsJobType {
  BillingData = 'BILLS',
  BillingBasedRecurringRevenueReport = 'BILL_MRR',
  CommitmentsLineItemsData = 'COMMITMENT_LINEITEMS',
  MonthOnMonthReport = 'MOM',
  PrepaymentsStatusReport = 'COMMITMENTS_REPORT',
  RecurringRevenueReport = 'PLAN_MRR',
  TotalContractValueReport = 'TCV',
  UsageData = 'USAGE',
}

export enum ApplyAsPricingType {
  Debit = 'DEBIT',
  ProductCredit = 'PRODUCT_CREDIT',
  GlobalCredit = 'GLOBAL_CREDIT',
}

export enum AutoApprovePeriodUnit {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
}

export enum BillingEntity {
  Contract = 'CONTRACT',
  AccountPlan = 'ACCOUNTPLAN',
  Prepayment = 'PREPAYMENT',
  Pricings = 'PRICINGS',
}

export enum DrawdownChargeTypes {
  MinimumSpend = 'MINIMUM_SPEND',
  StandingCharge = 'STANDING_CHARGE',
  Usage = 'USAGE',
}

export enum BalanceTransactionEntityType {
  Bill = 'BILL',
  Commitment = 'COMMITMENT',
  ServiceUser = 'SERVICE_USER',
  User = 'USER',
}

export enum BillJobFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
  AD_HOC = 'AD_HOC',
}

export enum BillJobStatus {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Initializing = 'INITIALIZING', // Fetching config and calculating which bills to generate
  Pending = 'PENDING', // Recently created
  Running = 'RUNNING', // Actually generating bills
}

export enum BillJobType {
  Create = 'CREATE',
  Recalculate = 'RECALCULATE',
}

// Extending the standard BillLineItemType with a few MRR-specific additions
export enum BillingBasedRecurringRevenueLineItemType {
  BalanceConsumed = 'BALANCE_CONSUMED',
  CommitmentConsumed = 'COMMITMENT_CONSUMED',
  CommitmentFee = 'COMMITMENT_FEE',
  CommitmentMrrAdjustment = 'COMMITMENT_MRR_ADJUSTMENT',
  CreditDeduction = 'CREDIT_DEDUCTION',
  CreditMemo = 'CREDIT_MEMO',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
  MinimumSpend = 'MINIMUM_SPEND',
  MinimumSpendMrrAdjustment = 'MINIMUM_SPEND_MRR_ADJUSTMENT',
  MinimumSpendRefund = 'MINIMUM_SPEND_REFUND',
  OverageSurcharge = 'OVERAGE_SURCHARGE',
  OverageUsage = 'OVERAGE_USAGE',
  StandingCharge = 'STANDING_CHARGE',
  Usage = 'USAGE',
  UsageCredit = 'USAGE_CREDIT',
}

export enum BillLineItemType {
  BalanceConsumed = 'BALANCE_CONSUMED',
  CommitmentConsumed = 'COMMITMENT_CONSUMED',
  CommitmentFee = 'COMMITMENT_FEE',
  CounterAdjustmentCredit = 'COUNTER_ADJUSTMENT_CREDIT',
  CounterAdjustmentDebit = 'COUNTER_ADJUSTMENT_DEBIT',
  CounterRunningTotalCharge = 'COUNTER_RUNNING_TOTAL_CHARGE',
  CreditDeduction = 'CREDIT_DEDUCTION',
  CreditMemo = 'CREDIT_MEMO',
  DebitMemo = 'DEBIT_MEMO',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
  MinimumSpend = 'MINIMUM_SPEND',
  MinimumSpendRefund = 'MINIMUM_SPEND_REFUND',
  OverageSurcharge = 'OVERAGE_SURCHARGE',
  OverageUsage = 'OVERAGE_USAGE',
  StandingCharge = 'STANDING_CHARGE',
  Usage = 'USAGE',
  UsageCredit = 'USAGE_CREDIT',
}

export enum BillStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
}

export enum ChildBillingMode {
  ParentBreakdown = 'PARENT_BREAKDOWN',
  ParentSummary = 'PARENT_SUMMARY',
  Child = 'CHILD',
}

export enum CreditType {
  Balance = 'BALANCE',
  Prepayment = 'PREPAYMENT',
}

export enum DataExplorerQueryType {
  Billing = 'BILL_LINE_ITEMS',
  Commitments = 'COMMITMENTS',
  Usage = 'USAGE_DATA',
}

export enum DataType {
  Account = 'account',
  AccountPlan = 'accountPlan',
  Aggregation = 'aggregation',
  Alert = 'alert',
  AnalyticsJob = 'analyticsJob',
  Balance = 'balance',
  BalanceTransaction = 'balanceTransaction',
  Bill = 'bill',
  BillConfig = 'billConfig',
  BillJob = 'billJob',
  BillLineItem = 'billLineItem',
  Commitment = 'commitment',
  CompoundAggregation = 'compoundAggregation',
  Contract = 'contract',
  Counter = 'counter',
  CounterAdjustment = 'counterAdjustment',
  CounterPricing = 'counterPricing',
  CreditLineItem = 'creditLineItem',
  CreditReason = 'creditReason',
  Currency = 'currency',
  CurrentUser = 'currentUser',
  Customer = 'customer',
  DebitLineItem = 'debitLineItem',
  DebitReason = 'debitReason',
  Destination = 'destination',
  ExternalMapping = 'externalMapping',
  ExternalMappingConfig = 'externalMappingConfig',
  Integration = 'integration',
  IntegrationCredential = 'integrationCredential',
  Invitation = 'invitation',
  MeasurmentsDeletion = 'measurementsDeletion',
  Meter = 'meter',
  M3terEvent = 'm3terEvent',
  NotificationRule = 'notificationRule',
  Organization = 'organization',
  OrganizationAdmin = 'organizationAdmin',
  OrganizationConfig = 'organizationConfig',
  OrganizationCustomFields = 'organizationCustomFields',
  PermissionPolicy = 'permissionPolicy',
  Plan = 'plan',
  PlanGroup = 'planGroup',
  PlanGroupLink = 'planGroupLink',
  PlanTemplate = 'planTemplate',
  Pricing = 'pricing',
  Product = 'product',
  ServiceUser = 'serviceUser',
  StatementDefintion = 'statementDefinition',
  StatementJob = 'statementJob',
  SupportAccess = 'supportAccess',
  SupportOrganization = 'supportOrganization',
  TransactionType = 'transactionType',
  DataExplorerSavedQuery = 'dataExplorerSavedQuery',
  UploadJob = 'uploadJob',
  User = 'user',
  UserAdmin = 'userAdmin',
  UserGroup = 'userGroup',
}

export enum ExternalMappingEntityType {
  Account = 'Account',
  AccountPlan = 'AccountPlan',
  AggregatedUsage = 'AggregatedUsage',
  Aggregation = 'Aggregation',
  Balance = 'Balance',
  Bill = 'Bill',
  BillItem = 'BillItem',
  BillLineItem = 'BillLineItem',
  BillLineItemPricingBand = 'BillLineItem__PricingBand',
  Commitment = 'Commitment',
  CompoundAggregation = 'CompoundAggregation',
  Contract = 'Contract',
  LedgerEntry = 'LedgerEntry',
  Meter = 'Meter',
  Organization = 'Organization',
  Plan = 'Plan',
  Pricing = 'Pricing',
  PricingBand = 'PricingBand',
  Product = 'Product',
}

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
}

export enum IntegrationConfigParameterType {
  AuthLink = 'AuthLink',
  Boolean = 'Boolean',
  IdList = 'IdList',
  Integer = 'Integer',
  String = 'String',
  Map = 'Map',
  MultiSelectList = 'MultiSelectList',
  OptionList = 'OptionList',
  UserList = 'UserList',
}

export enum IntegrationRunViewEntityTypes {
  Bill = 'Bill',
  Notification = 'Notification',
}

export enum MeterFieldCategory {
  WHO = 'WHO',
  WHAT = 'WHAT',
  WHERE = 'WHERE',
  MEASURE = 'MEASURE',
  METADATA = 'METADATA',
  INCOME = 'INCOME',
  COST = 'COST',
  OTHER = 'OTHER',
}

export enum PermissionEffect {
  Allow = 'allow',
  Deny = 'deny',
}

export enum RecurringRevenueReportMonthConfig {
  Calendar = 'CALENDAR',
  Actual = 'ACTUAL',
  ThirtyDays = 'DAYS30',
  ThirtyOneDays = 'DAYS31',
}

export enum Rounding {
  None = 'NONE',
  Down = 'DOWN',
  Up = 'UP',
  Nearest = 'NEAREST',
}

export enum StatementAggregationFrequency {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
  WholePeriod = 'WHOLE_PERIOD',
}

export enum StatementAutoGenerateMode {
  None = 'NONE',
  Json = 'JSON',
  JsonAndCsv = 'JSON_AND_CSV',
}

export enum StatementJobStatus {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Running = 'RUNNING',
}

// Used in the data explorer.
export enum UsageDataFrequency {
  Original = 'ORIGINAL',
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  WholePeriod = 'WHOLE_PERIOD',
}
