import React, { ReactNode, Suspense, useCallback } from 'react';

import { Outlet } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

import {
  AppLayoutMain,
  AppLayoutNavigation,
  NavigationPanel,
} from '@m3ter-com/ui-components';

import { PreferenceName } from '@/types/preferences';

import { PageHeader } from '@/components/common/navigation/PageHeader';
import usePreference from '@/hooks/data/usePreference';

export interface RouteWrapperProps {
  element?: ReactNode;
  navigation?: ReactNode;
  navigationActions?: ReactNode;
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  element = <Outlet />,
  navigation,
  navigationActions,
}) => {
  const [isNavCollapsed, setIsNavCollapsed] = usePreference(
    PreferenceName.NAV_COLLAPSED,
    false
  );

  const onNavCollapseToggle = useCallback(() => {
    setIsNavCollapsed((value) => !value);
  }, [setIsNavCollapsed]);

  return (
    <React.Fragment>
      {navigation && (
        <AppLayoutNavigation>
          <NavigationPanel
            actions={navigationActions}
            isCollapsed={isNavCollapsed}
            onCollapseToggle={onNavCollapseToggle}
          >
            {navigation}
          </NavigationPanel>
        </AppLayoutNavigation>
      )}
      <AppLayoutMain>
        <PageHeader />
        {/* Suspense boundary so that sub navs and breadcrumbs remain visible while lazy child components load. */}
        <Suspense fallback={<Spinner />}>{element}</Suspense>
      </AppLayoutMain>
    </React.Fragment>
  );
};
