import {
  all,
  call,
  select,
  takeLatest,
  StrictEffect,
} from 'redux-saga/effects';

import { PathParams, QueryParams } from '@m3ter-com/m3ter-api';

import type { AnyAction } from 'redux';
import {
  DataExplorerDataType,
  DataExplorerDataTypeToRequestBody,
} from '@/types/data';

import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import { dataExplorerDataTypeToLoader } from './loaders';
import {
  batchLoadDataExplorerData,
  loadDataExplorerData,
  LoadDataExplorerDataAction,
  BatchLoadDataExplorerDataAction,
} from './dataExplorer';

export function* dataExplorerDataLoaderSaga(
  dataType: DataExplorerDataType,
  body: DataExplorerDataTypeToRequestBody[DataExplorerDataType],
  pathParams?: PathParams,
  queryParams?: QueryParams,
  loadExportUrl?: boolean
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);
  const dataLoader = dataExplorerDataTypeToLoader[dataType];
  yield call(
    dataLoader,
    organizationId,
    body,
    pathParams,
    queryParams,
    loadExportUrl
  );
}

export function* batchLoadDataExplorerDataSaga(
  action: BatchLoadDataExplorerDataAction
): Generator<StrictEffect, void, any> {
  const dataLoadingCalls = action.payload.actions.map((act) =>
    call(
      dataExplorerDataLoaderSaga,
      act.dataType,
      act.body,
      act.pathParams,
      act.queryParams,
      act.loadExportUrl
    )
  );
  yield all(dataLoadingCalls);
}

export function* loadDataExplorerDataSaga<DT extends DataExplorerDataType>(
  action: LoadDataExplorerDataAction<DT>
): Generator<StrictEffect, void, any> {
  yield call(
    dataExplorerDataLoaderSaga,
    action.payload.dataType,
    action.payload.body,
    action.payload.pathParams,
    action.payload.queryParams,
    action.payload.loadExportUrl
  );
}

export default function* dataExplorerSaga() {
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.BillingData,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.CommitmentsData,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.CommitmentsLineItemsData,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.CommitmentsObligationsData,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.UsageData,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.UsageMeterDimensions,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    (action: AnyAction) =>
      action.type === loadDataExplorerData.type &&
      (action as LoadDataExplorerDataAction<DataExplorerDataType>).payload
        .dataType === DataExplorerDataType.UsageMeters,
    loadDataExplorerDataSaga
  );
  yield takeLatest(
    batchLoadDataExplorerData.type,
    batchLoadDataExplorerDataSaga
  );
}
