import React, { PropsWithChildren } from 'react';

import {
  Box,
  chakra,
  forwardRef,
  LinkProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export const HeaderNavigation: React.FC<PropsWithChildren> = ({ children }) => {
  const styles = useMultiStyleConfig('HeaderNavigation', {});

  return <chakra.nav sx={styles.nav}>{children}</chakra.nav>;
};

export interface HeaderNavigationItemProps extends LinkProps {
  isActive?: boolean;
}

export const HeaderNavigationItem = forwardRef<
  HeaderNavigationItemProps,
  'div'
>(({ isActive, children, ...rest }, ref) => {
  const styles = useMultiStyleConfig('HeaderNavigation', { isActive });

  return (
    <Box ref={ref} {...rest} sx={styles.item}>
      {children}
    </Box>
  );
});
