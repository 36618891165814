import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import sortBy from 'lodash/sortBy';
import {
  Box,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Divider,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from 'lucide-react';

import { Organization } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  CaretDownIcon,
  HeaderActionButton,
  SearchBar,
} from '@m3ter-com/ui-components';

import useNamedNavigate from '@/hooks/navigation/useNamedNavigate';
import useOrg from '@/hooks/data/crud/useOrg';
import {
  selectStandardOrgs,
  selectSupportOrgs,
} from '@/store/app/bootstrap/bootstrap';

import { OrgSwitcherOrg } from './OrgSwitcherOrg';

interface OrgOption {
  organization: Organization;
  isSupportAccess: boolean;
}

// Filters orgs based on the name starting with the search term
// or matching the start of any word.
const filterOrgs = (
  orgs: Array<Organization>,
  searchTerm: string
): Array<Organization> => {
  const lcSearchTerm = searchTerm.toLowerCase();

  return orgs.filter((org) => {
    const orgName = org.organizationName.toLowerCase();
    return (
      orgName.startsWith(lcSearchTerm) ||
      orgName.split(' ').some((word) => word.startsWith(lcSearchTerm))
    );
  });
};

export const OrgSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { currentOrgId, currentOrg } = useOrg();
  const standardOrgs = useSelector(selectStandardOrgs);
  const supportOrgs = useSelector(selectSupportOrgs);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  const filteredStandardOrgs = useMemo(() => {
    const orgs = sortBy(standardOrgs, 'organizationName');
    return searchTerm !== '' ? filterOrgs(orgs, searchTerm) : orgs;
  }, [searchTerm, standardOrgs]);

  const filteredSupportOrgs = useMemo(() => {
    const orgs = sortBy(
      // Remove orgs that are already in the standard orgs to avoid duplicates.
      differenceBy(supportOrgs, standardOrgs, 'id'),
      'organizationName'
    );
    return searchTerm !== '' ? filterOrgs(orgs, searchTerm) : orgs;
  }, [searchTerm, supportOrgs, standardOrgs]);

  const filteredOrgOptions = useMemo<Array<OrgOption>>(
    () => [
      ...filteredStandardOrgs.map((org) => ({
        organization: org,
        isSupportAccess: false,
      })),
      ...filteredSupportOrgs.map((org) => ({
        organization: org,
        isSupportAccess: true,
      })),
    ],
    [filteredStandardOrgs, filteredSupportOrgs]
  );

  const navigate = useNamedNavigate();

  const [active, setActive] = useState(0);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (active + 1 < filteredOrgOptions.length) {
            setActive(active + 1);
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (active > 0) {
            setActive(active - 1);
          }
          break;
        case 'Enter':
          if (filteredOrgOptions.length > 0) {
            navigate('organization', {
              orgId: filteredOrgOptions[active].organization.id,
            });
          }
          break;
        default:
        // Do nothing
      }
    },
    [active, filteredOrgOptions, navigate]
  );

  useEffect(() => {
    setActive(0);
  }, [searchTerm]);

  return (
    <Popover placement="bottom-end" initialFocusRef={initialFocusRef}>
      <PopoverTrigger data->
        <Flex
          gap={2}
          alignItems="center"
          color="white"
          data-testid="org-menu-button"
        >
          <Box maxWidth="220px">
            <Text fontSize="xs">{t('common:organization')}</Text>
            <Text
              fontWeight="bold"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {currentOrg ? currentOrg.organizationName : '-'}
            </Text>
          </Box>
          <CaretDownIcon />
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <SearchBar
            searchOnType
            ref={initialFocusRef}
            debounceWait={100}
            onSearch={setSearchTerm}
            onClear={clearSearchTerm}
            placeholder={t('common:searchForOrganization')}
            mb={2}
            onKeyDown={onKeyDown}
          />
          <Box overflowY="scroll" maxH="200px" role="listbox">
            {filteredOrgOptions.length === 0 ? (
              <Text p={2} textAlign="center">
                {t('common:noOrganizationsMatch')}
              </Text>
            ) : (
              filteredOrgOptions.map((option, index) => {
                const selected = index === active;
                return (
                  <OrgSwitcherOrg
                    key={option.organization.id}
                    organization={option.organization}
                    isSelected={selected}
                    isCurrent={option.organization.id === currentOrgId}
                    isSupportAccess={option.isSupportAccess}
                  />
                );
              })
            )}
          </Box>
        </PopoverBody>
        <Divider />
        <HeaderActionButton
          as={Link}
          to="/"
          leftIcon={<ExternalLinkIcon size={16} />}
        >
          {t('common:viewAllOrganizations')}
        </HeaderActionButton>
      </PopoverContent>
    </Popover>
  );
};
