import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import {
  DataType,
  listAll,
  ListResponse,
  PermissionPolicy,
  SupportAccess,
  updateEntityPermissionPolicy,
} from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { ids } from '@/util/data';
import { updateSingletonData } from '@/store/data/data.saga';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  updateSupportAccess,
  UpdateSupportAccessAction,
  updateSupportAccessFailure,
  updateSupportAccessSuccess,
} from './supportAccessUpdate';

export function* updateSupportAccessSaga(
  action: UpdateSupportAccessAction
): Generator<StrictEffect, void, any> {
  const { formValues } = action.payload;
  const { onSuccess, onFailure } = action.meta;
  const { permissionPolicyIds, ...supportAccessValues } = formValues;

  try {
    const organizationId = yield select(selectCurrentOrgId);

    const supportAccess: SupportAccess = yield call(
      updateSingletonData,
      DataType.SupportAccess,
      supportAccessValues
    );

    const { data: permissionPolicies }: ListResponse<PermissionPolicy> =
      yield call(listAll, {
        dataType: DataType.PermissionPolicy,
        actionName: 'listSupportAccessPermissions',
        pathParams: { organizationId },
      });

    const currentPermissionIds = ids(permissionPolicies);
    const permissionPolicyIdsToRemove = currentPermissionIds.filter(
      (id) => !permissionPolicyIds.includes(id)
    );
    const permissionPolicyIdsToAdd = permissionPolicyIds.filter(
      (id) => !currentPermissionIds.includes(id)
    );

    if (permissionPolicyIdsToAdd.length > 0) {
      yield all(
        permissionPolicyIdsToAdd.map((permissionPolicyId) =>
          call(updateEntityPermissionPolicy, {
            actionName: 'addtosupportusers',
            organizationId,
            permissionPolicyId,
          })
        )
      );
    }

    if (permissionPolicyIdsToRemove.length > 0) {
      yield all(
        permissionPolicyIdsToRemove.map((permissionPolicyId) =>
          call(updateEntityPermissionPolicy, {
            actionName: 'removefromsupportusers',
            organizationId,
            permissionPolicyId,
          })
        )
      );
    }

    yield put(updateSupportAccessSuccess(supportAccess, onSuccess));
  } catch (error) {
    yield put(updateSupportAccessFailure(extractError(error), onFailure));
  }
}

export default function* supportAccessUpdateSaga() {
  yield takeEvery(updateSupportAccess.type, updateSupportAccessSaga);
}
