import {
  DataExplorerCommitmentsDataRequestBody,
  DataExplorerCommitmentsDataResponse,
  DataExplorerCommitmentsObligationsResponse,
  DataExplorerExportResponse,
  Id,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { post } from '../client';

export const getDataExplorerCommitmentsData = (
  organizationId: Id,
  body: DataExplorerCommitmentsDataRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/commitments/v2'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerCommitmentsDataResponse>;

export const getDataExplorerCommitmentsDataExportLink = (
  organizationId: Id,
  body: DataExplorerCommitmentsDataRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/commitments/download/csv'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerExportResponse>;

export const getDataExplorerCommitmentsObligationsData = (
  organizationId: Id,
  body: DataExplorerCommitmentsDataRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/commitments/obligations'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerCommitmentsObligationsResponse>;

export const getDataExplorerCommitmentsObligationsDataExportLink = (
  organizationId: Id,
  body: DataExplorerCommitmentsDataRequestBody
) =>
  post({
    path: getOrganizationPath(
      '/dataexplorer/commitments/obligations/download/csv'
    ),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerExportResponse>;
