import { all, call, put, takeLatest, StrictEffect } from 'redux-saga/effects';

import { DataType } from '@m3ter-com/m3ter-api';

import { hasAny } from '@/store/data/data.saga';
import {
  loadData,
  loadDataSuccess,
  loadDataFailure,
} from '@/store/features/onboarding/onboarding';
import { extractError } from '@/util/error';

export function* loadDataSaga(): Generator<StrictEffect, void, any> {
  try {
    // We can make the content check requests in parallel.
    const [
      hasProduct,
      hasMeter,
      hasAggregation,
      hasPricing,
      hasCounterPricing,
      hasCounter,
    ] = yield all([
      call(hasAny, DataType.Product),
      call(hasAny, DataType.Meter),
      call(hasAny, DataType.Aggregation),
      call(hasAny, DataType.Pricing),
      call(hasAny, DataType.CounterPricing),
      call(hasAny, DataType.Counter),
    ]);

    yield put(
      loadDataSuccess(
        hasProduct,
        hasMeter,
        hasAggregation,
        hasPricing,
        hasCounterPricing,
        hasCounter
      )
    );
  } catch (error) {
    yield put(loadDataFailure(extractError(error)));
  }
}

export default function* onboardingSaga() {
  yield takeLatest(loadData.type, loadDataSaga);
}
