import React from 'react';

import { Link, To, useMatch, useResolvedPath } from 'react-router-dom';
import { forwardRef } from '@chakra-ui/react';

import {
  HeaderNavigationItem,
  HeaderNavigationItemProps,
} from '@m3ter-com/ui-components';

export interface HeaderNavLinkProps extends HeaderNavigationItemProps {
  to: To;
  activeOnlyIfExact?: boolean;
}

export const HeaderNavLink = forwardRef<HeaderNavLinkProps, 'a'>(
  ({ to, activeOnlyIfExact = false, ...props }, ref) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: activeOnlyIfExact });

    return (
      <HeaderNavigationItem
        ref={ref}
        as={Link}
        isActive={!!match}
        to={to}
        {...props}
      />
    );
  }
);
