import React, { useCallback, useMemo, useState } from 'react';

import { Skeleton } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  evaluationNotificationCalculation,
  getEventTypes,
} from '@m3ter-com/m3ter-api';
import { FormStack } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormSelect,
  FormSwitch,
  FormTextarea,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { AppError } from '@/types/errors';

import useOrg from '@/hooks/data/crud/useOrg';
import useAppQuery from '@/hooks/data/useAppQuery';
import { extractError } from '@/util/error';
import { ErrorAlerts } from '@/components/common/errors/ErrorAlerts';
import { NotificationRuleCalculationDetails } from '@/components/features/notification-rules/NotificationRuleCalculationDetails';
import { Option } from '@/components/forms/FormWildcardMultiSelect';

export interface NotificationRuleFormInnerProps {
  isSaving: boolean;
  isEdit: boolean;
  onCancel?: () => void;
}

export const NotificationRuleFormInner: React.FC<
  NotificationRuleFormInnerProps
> = ({ isSaving, isEdit, onCancel }) => {
  const { t } = useTranslation();
  const entityName = t('common:notificationRule');
  const { setError, clearErrors } = useFormContext();

  const { currentOrgId: organizationId } = useOrg();

  const [calculationResult, setCalculationResult] = useState<string>();
  const [calculationError, setCalculationError] = useState<AppError>();

  const eventName = useWatch({ name: 'eventName' });
  const calculation = useWatch({ name: 'calculation' });
  const isEventAlwaysFired: boolean = useWatch({ name: 'alwaysFireEvent' });

  const calculationParams = useMemo(
    () => ({
      calculation,
      eventName,
    }),
    [calculation, eventName]
  );

  const {
    isLoading: isLoadingEventTypes,
    data: eventTypesResponse,
    error: eventTypesError,
  } = useAppQuery({
    queryFn: () => getEventTypes(organizationId),
    queryKey: ['eventTypes', organizationId],
  });

  const eventTypesOptions = useMemo<Array<Option>>(
    () =>
      eventTypesResponse?.events
        ? eventTypesResponse.events.map((eventType) => ({
            label: eventType,
            value: eventType,
          }))
        : [],
    [eventTypesResponse?.events]
  );

  const onTestCalculation = useCallback(() => {
    evaluationNotificationCalculation(organizationId, {
      calculation,
      eventName,
    })
      .then((response: { success: boolean; errorMessage?: string }) => {
        setCalculationResult(
          response.success ? t('common:true') : t('common:false')
        );

        if (response.errorMessage) {
          setError('calculation', {
            type: 'custom',
            message: response.errorMessage,
          });
        } else {
          clearErrors('calculation');
        }
      })
      .catch((error) => {
        setCalculationError(extractError(error));
        setCalculationResult(t('common:false'));
      });
  }, [t, organizationId, calculation, eventName, setError, clearErrors]);

  const errors = useMemo<Array<AppError | undefined>>(
    () => [eventTypesError, calculationError],
    [calculationError, eventTypesError]
  );

  return (
    <Skeleton isLoaded={!isLoadingEventTypes}>
      <FormStack mb={4}>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <ErrorAlerts errors={errors} />
          <NameCodeFields />
          <FormField
            isRequired
            name="description"
            label={t('forms:labels.description')}
            control={FormInput}
          />
        </FormSection>
        <FormSection
          heading={t('forms:labels.entitySettings', {
            entityName,
          })}
        >
          <FormField
            isRequired
            name="eventName"
            label={t('forms:labels.event')}
            options={eventTypesOptions}
            control={FormSelect}
            isSearchable
            searchMatchFrom="any"
          />
          <FormField
            name="alwaysFireEvent"
            label={t('features:notifications.alwaysFireNotification')}
            control={FormSwitch}
            helpText={t('forms:helpText.alwaysFireNotification')}
          />
          <FormField
            isRequired
            name="active"
            label={t('common:active')}
            control={FormSwitch}
            helpText={t('forms:helpText.notificationActive')}
          />
          <FormField
            isRequired={!isEventAlwaysFired}
            name="calculation"
            label={t('forms:labels.calculation')}
            control={FormTextarea}
            isDisabled={isEventAlwaysFired}
          />
        </FormSection>
        <NotificationRuleCalculationDetails
          calculationParams={calculationParams}
          calculationResult={calculationResult}
          isDisabled={isEventAlwaysFired}
          onTestCalculation={onTestCalculation}
        />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
          isSubmitDisabled={!eventName}
        />
      </FormStack>
    </Skeleton>
  );
};
