import React, { ReactNode } from 'react';

import { forwardRef, Tooltip } from '@chakra-ui/react';

import { Button, ButtonProps } from './Button';
import { IconButtonProps } from './IconButton';
import { ButtonBadge } from './ButtonBadge';

export interface BadgeIconButtonProps
  extends ButtonProps,
    Pick<IconButtonProps, 'icon'> {
  addTooltip?: boolean;
  badge?: ReactNode;
}

export const BadgeIconButton = forwardRef<BadgeIconButtonProps, 'button'>(
  ({ addTooltip, badge, icon, ...props }, ref) => {
    // We use our standard Button over an IconButton here because chakra's
    // IconButton doesn't allow you to render both an icon and some children
    const button = (
      <Button ref={ref} p={0} {...props}>
        {icon}
        {badge && <ButtonBadge>{badge}</ButtonBadge>}
      </Button>
    );

    return addTooltip ? (
      // eslint-disable-next-line react/destructuring-assignment
      <Tooltip label={props['aria-label']}>{button}</Tooltip>
    ) : (
      button
    );
  }
);
