import React, { PropsWithChildren } from 'react';

import {
  Avatar,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { Button, ButtonProps } from '../../controls/Button';
import { CaretDownIcon } from '../../icons/CaretDownIcon';

export interface HeaderActionsProps {
  fullName: string;
  email: string;
  avatar?: string;
}

export interface HeaderActionButtonProps extends ButtonProps {}

export const HeaderActions: React.FC<PropsWithChildren<HeaderActionsProps>> = ({
  fullName,
  email,
  avatar,
  children,
}) => {
  const image = <Avatar name={fullName} src={avatar} verticalAlign="middle" />;

  const styles = useMultiStyleConfig('HeaderActions');

  return (
    <Stack direction="row" alignItems="center">
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Flex
            alignItems="center"
            cursor="pointer"
            data-testid="user-menu-button"
            sx={styles.trigger}
          >
            {image}
            <CaretDownIcon />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverHeader sx={styles.header}>
              {image}
              <Text mt={2} fontWeight="bold">
                {fullName}
              </Text>
              <Text>{email}</Text>
            </PopoverHeader>
            <PopoverBody sx={styles.body}>{children}</PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Stack>
  );
};

export const HeaderActionButton = forwardRef<HeaderActionButtonProps, 'button'>(
  (props, ref) => {
    const styles = useMultiStyleConfig('HeaderActions');

    return <Button ref={ref} {...props} sx={styles.button} />;
  }
);
