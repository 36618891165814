import React from 'react';

import { Params } from 'react-router-dom';

import useNamedLink from '@/hooks/navigation/useNamedLink';
import {
  HeaderNavIconLink,
  HeaderNavIconLinkProps,
} from '@/components/common/navigation/HeaderNavIconLink';

export interface NamedHeaderNavIconLinkProps
  extends Omit<HeaderNavIconLinkProps, 'to'> {
  name: string;
  params?: Params;
  queryParams?: Record<string, any>;
}

export const NamedHeaderNavIconLink: React.FC<NamedHeaderNavIconLinkProps> = ({
  name,
  params,
  queryParams,
  ...props
}) => {
  const link = useNamedLink(name, params, queryParams);

  return <HeaderNavIconLink to={link} {...props} />;
};
