import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { Bill, getBillStatementUrl } from '@m3ter-com/m3ter-api';

import useDownloadUrl from '@/hooks/util/useDownloadUrl';
import useOrg from '@/hooks/data/crud/useOrg';
import {
  generateStatements,
  selectGenerateStatementsError,
  selectRunningStatementJobs,
} from '@/store/features/billing/statementJobs';

const useBillStatements = (bill: Bill) => {
  const billId = bill.id;

  const dispatch = useDispatch();
  const { currentOrgId: organizationId } = useOrg();
  const runningStatementJobs = useSelector(selectRunningStatementJobs);

  const isGenerating = runningStatementJobs.some(
    (statementJob) => statementJob.billId === billId
  );

  const {
    isOpen: isGenerateStatementsModalOpen,
    onOpen: openGenerateStatementsModal,
    onClose: closeGenerateStatementsModal,
  } = useDisclosure();

  const { download: downloadCsv, downloadError: downloadCsvError } =
    useDownloadUrl(() => getBillStatementUrl(organizationId, billId, 'csv'));

  const { download: downloadJson, downloadError: downloadJsonError } =
    useDownloadUrl(() => getBillStatementUrl(organizationId, billId, 'json'));

  const generate = useCallback(
    (generateCsvStatement: boolean) => {
      dispatch(generateStatements(billId, generateCsvStatement));
    },
    [dispatch, billId]
  );

  const generateError = useSelector(selectGenerateStatementsError);

  return {
    isGenerateStatementsModalOpen,
    isGenerating,
    generateError,
    downloadCsv,
    downloadJson,
    downloadError: downloadCsvError || downloadJsonError,
    generate,
    closeGenerateStatementsModal,
    openGenerateStatementsModal,
  };
};

export default useBillStatements;
