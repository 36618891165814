import { useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { getOrganizationPath } from '@m3ter-com/m3ter-api/src/util/path';
import { put } from '@m3ter-com/m3ter-api/src/client';

import { extractError } from '@/util/error';
import useNotification from '@/hooks/util/useNotification';
import useAppMutation from '@/hooks/data/useAppMutation';
import { OrganizationAdminResendPasswordModalFormValues } from '@/components/features/admin/organizations/OrganizationAdminResendPasswordModal';

const useResendTempPassword = (organizationId: Id) => {
  const { t } = useTranslation();
  const { toast } = useNotification();

  const {
    isOpen: isResendPasswordModalOpen,
    onOpen: onOpenResendPasswordModal,
    onClose: onCloseResendPasswordModal,
  } = useDisclosure();

  const {
    error: resendingPasswordError,
    isPending: isResendingPassword,
    mutate: resendTempPassword,
  } = useAppMutation({
    mutationFn: async (userId: Id) => {
      const pathParams = {
        organizationId,
        userId,
      };
      return put({
        path: getOrganizationPath('/users/:userId/password/resend'),
        pathParams,
      });
    },
    onSuccess: () => {
      toast({
        status: 'success',
        description: t('notifications:tempPasswordResentSuccess'),
        duration: 5000,
      });
    },
    onError: (error) => {
      const appError = extractError(error);
      toast({
        status: 'error',
        description: t('notifications:tempPasswordResentFailure').replace(
          '{error}',
          appError.message
        ),
      });
    },
  });

  const onResendTempPassword = useCallback(
    ({ userId }: OrganizationAdminResendPasswordModalFormValues) => {
      resendTempPassword(userId);
    },
    [resendTempPassword]
  );

  return {
    isResendPasswordModalOpen,
    onOpenResendPasswordModal,
    onCloseResendPasswordModal,
    onResendTempPassword,
    isResendingPassword,
    resendingPasswordError,
  };
};

export default useResendTempPassword;
