import React from 'react';

import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react';

export const AppLayout: React.FC<BoxProps> = (boxProps) => {
  const styles = useMultiStyleConfig('AppLayout', {});

  return <Box __css={styles.wrapper} {...boxProps} />;
};

export const AppLayoutHeader: React.FC<BoxProps> = (boxProps) => {
  const styles = useMultiStyleConfig('AppLayout', {});

  return <Box __css={styles.header} {...boxProps} />;
};

export const AppLayoutNavigation: React.FC<BoxProps> = (boxProps) => {
  const styles = useMultiStyleConfig('AppLayout', {});

  return <Box __css={styles.navigation} {...boxProps} />;
};

export const AppLayoutMain: React.FC<BoxProps> = (boxProps) => {
  const styles = useMultiStyleConfig('AppLayout', {});

  return <Box as="main" __css={styles.main} {...boxProps} />;
};
