import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { UserAdmin } from '@m3ter-com/m3ter-api';
import { FormValidationContext } from '@m3ter-com/console-core/components';

import { UserAdminFormExtraData } from '@/components/features/admin/users/UserAdminForm';

const suite = create(
  'userAdmin',
  (
    data: Partial<UserAdmin>,
    context: FormValidationContext<{}, UserAdminFormExtraData>
  ) => {
    const { isEdit } = context.extraData;

    test(
      'firstName',
      i18next.t('forms:validations.users.firstNameRequired'),
      () => {
        enforce(data.firstName).isNotEmpty();
      }
    );

    test(
      'lastName',
      i18next.t('forms:validations.users.lastNameRequired'),
      () => {
        enforce(data.lastName).isNotEmpty();
      }
    );

    test(
      'email',
      i18next.t('forms:validations.users.emailAddressRequired'),
      () => {
        enforce(data.email).isNotEmpty();
      }
    );

    omitWhen(isEdit, () => {
      test(
        'tempPassword',
        i18next.t('forms:validations.users.passwordRequired'),
        () => {
          enforce(data.tempPassword).isNotEmpty();
        }
      );
    });
  }
);

export default suite;
