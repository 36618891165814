import React from 'react';

import {
  BadgeDollarSignIcon,
  BoxesIcon,
  SquareDashedKanbanIcon,
  SquareKanbanIcon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';

export const PricingNavigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Plan, CrudRouteType.List)}
        icon={<SquareKanbanIcon />}
      >
        {t('common:plans')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.PlanTemplate, CrudRouteType.List)}
        icon={<SquareDashedKanbanIcon />}
      >
        {t('common:planTemplates')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.PlanGroup, CrudRouteType.List)}
        icon={<BoxesIcon />}
      >
        {t('common:planGroups')}
      </NamedNavLink>
      <NamedNavLink name="pricing.editor" icon={<BadgeDollarSignIcon />}>
        {t('features:pricing.pricingEditor')}
      </NamedNavLink>
    </Navigation>
  );
};
