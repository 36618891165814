import { SystemStyleObject, ComponentMultiStyleConfig } from '@chakra-ui/react';
import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const parts = ['nav', 'item'];

const baseStyleNav: SystemStyleObject = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'stretch',
  height: '100%',
  gap: 8,
};

const baseStyleItem: SystemStyleFunction = ({ isActive }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  color: isActive ? 'green-adaptive' : 'gray.100',
  ':hover': {
    color: 'green-adaptive',
    textDecoration: 'none',
  },
  ':after': isActive
    ? {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: -2,
        right: -2,
        display: 'block',
        height: 1,
        backgroundColor: 'green-adaptive',
        borderTopRadius: 'full',
      }
    : undefined,
});

const navigation: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    nav: baseStyleNav,
    item: baseStyleItem(props),
  }),
};

export default navigation;
