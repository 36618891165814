import React from 'react';

import { Link, To, useMatch, useResolvedPath } from 'react-router-dom';
import { forwardRef } from '@chakra-ui/react';

import {
  BadgeIconButton,
  BadgeIconButtonProps,
  HeaderNavigationItem,
} from '@m3ter-com/ui-components';
import {} from '@m3ter-com/ui-components/src/components/controls/Button';

export interface HeaderNavIconLinkProps
  extends Pick<BadgeIconButtonProps, 'badge' | 'icon'> {
  activeOnlyIfExact?: boolean;
  label: string;
  to: To;
}

export const HeaderNavIconLink = forwardRef<HeaderNavIconLinkProps, 'a'>(
  ({ activeOnlyIfExact = false, badge, icon, label, to }, ref) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: activeOnlyIfExact });

    return (
      <HeaderNavigationItem ref={ref} isActive={!!match} as={Link} to={to}>
        <BadgeIconButton
          addTooltip
          aria-label={label}
          alignSelf="stretch"
          as="span"
          badge={badge}
          icon={icon}
          variant="header-nav"
        />
      </HeaderNavigationItem>
    );
  }
);
