import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import {
  getServiceUserCredentials,
  createServiceUserCredential,
  activateServiceUserCredential,
  deactivateServiceUserCredential,
  deleteServiceUserCredential,
  ServiceUserCredential,
  ServiceUserCredentialsResponse,
} from '@m3ter-com/m3ter-api';

import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { extractError } from '@/util/error';
import {
  removeServiceUserCredential,
  RemoveServiceUserCredentialPayload,
  removeServiceUserCredentialSuccess,
  removeServiceUserCredentialFailure,
  ServiceUserCredentialStatusChangePayload,
  makeServiceUserCredentialInactiveSuccess,
  makeServiceUserCredentialInactiveFailure,
  makeServiceUserCredentialActiveFailure,
  makeServiceUserCredentialActiveSuccess,
  LoadServiceUserCredentialsAction,
  loadServiceUserCredentialsSuccess,
  loadServiceUserCredentialsFailure,
  loadServiceUserCredentials,
  makeServiceUserCredentialActive,
  makeServiceUserCredentialInactive,
  createServiceUserCredential as createServiceUserCredentialAction,
  CreateServiceUserCredentialAction,
  createServiceUserCredentialSuccess,
  createServiceUserCredentialFailure,
} from '@/store/features/access/service-user/serviceUserCredentials';

export function* loadServiceUserCredentialsSaga(
  action: LoadServiceUserCredentialsAction
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId) {
      const response: ServiceUserCredentialsResponse = yield call(
        getServiceUserCredentials,
        organizationId,
        serviceUserId
      );
      yield put(loadServiceUserCredentialsSuccess(response.data));
    }
  } catch (error) {
    yield put(loadServiceUserCredentialsFailure(extractError(error)));
  }
}

export function* removeServiceUserCredentialSaga(
  action: PayloadAction<RemoveServiceUserCredentialPayload>
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId, apiKey } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId && apiKey) {
      const response: ServiceUserCredential = yield call(
        deleteServiceUserCredential,
        organizationId,
        serviceUserId,
        apiKey
      );
      yield put(removeServiceUserCredentialSuccess(response.apiKey));
    }
  } catch (error) {
    yield put(removeServiceUserCredentialFailure(extractError(error)));
  }
}

export function* makeServiceUserCredentialInactiveSaga(
  action: PayloadAction<ServiceUserCredentialStatusChangePayload>
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId, apiKey } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId && apiKey) {
      const updatedCredential: ServiceUserCredential = yield call(
        deactivateServiceUserCredential,
        organizationId,
        serviceUserId,
        apiKey
      );
      yield put(makeServiceUserCredentialInactiveSuccess(updatedCredential));
    }
  } catch (error) {
    yield put(makeServiceUserCredentialInactiveFailure(extractError(error)));
  }
}

export function* makeServiceUserCredentialActiveSaga(
  action: PayloadAction<ServiceUserCredentialStatusChangePayload>
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId, apiKey } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId && apiKey) {
      const updatedCredential: ServiceUserCredential = yield call(
        activateServiceUserCredential,
        organizationId,
        serviceUserId,
        apiKey
      );
      yield put(makeServiceUserCredentialActiveSuccess(updatedCredential));
    }
  } catch (error) {
    yield put(makeServiceUserCredentialActiveFailure(extractError(error)));
  }
}

export function* createServiceUserCredentialSaga(
  action: CreateServiceUserCredentialAction
): Generator<StrictEffect, void, any> {
  const { serviceUserId } = action.payload;
  const organizationId = yield select(selectCurrentOrgId);

  try {
    const credential: ServiceUserCredential = yield call(
      createServiceUserCredential,
      organizationId,
      serviceUserId
    );
    yield put(createServiceUserCredentialSuccess(credential));
  } catch (error) {
    yield put(createServiceUserCredentialFailure(extractError(error)));
  }
}

export default function* serviceUserCredentialsSaga() {
  yield takeEvery(
    removeServiceUserCredential.type,
    removeServiceUserCredentialSaga
  );
  yield takeEvery(
    makeServiceUserCredentialInactive.type,
    makeServiceUserCredentialInactiveSaga
  );
  yield takeEvery(
    makeServiceUserCredentialActive.type,
    makeServiceUserCredentialActiveSaga
  );
  yield takeEvery(
    loadServiceUserCredentials.type,
    loadServiceUserCredentialsSaga
  );
  yield takeEvery(
    createServiceUserCredentialAction.type,
    createServiceUserCredentialSaga
  );
}
