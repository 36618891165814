import React from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalHeader,
  ButtonGroup,
  ModalFooter,
} from '@chakra-ui/react';

import { DataType, Id, UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack } from '@m3ter-com/ui-components';
import { Form, FormField } from '@m3ter-com/console-core/components';

import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import userAdminResendTempPasswordSchema from '@/validation/userAdminResendTempPassword';

export interface OrganizationAdminResendPasswordModalFormValues {
  userId: Id;
}

export interface OrganizationAdminResendPasswordModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (data: OrganizationAdminResendPasswordModalFormValues) => void;
}

const defaultInitialValues: Partial<OrganizationAdminResendPasswordModalFormValues> =
  {};

export const OrganizationAdminResendPasswordModal: React.FC<
  OrganizationAdminResendPasswordModalProps
> = ({ isOpen = false, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const userAccessor = (item: UserAdmin) =>
    `${item.firstName} ${item.lastName}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          initialValues={defaultInitialValues}
          validationSchema={userAdminResendTempPasswordSchema}
        >
          <ModalHeader>
            {t('features:admin.resendUsersTempPassword')}
          </ModalHeader>
          <ModalBody>
            <FormStack>
              <FormField
                isRequired
                name="userId"
                label={t('common:user')}
                dataType={DataType.UserAdmin}
                control={
                  FormEntitySelect as DataTypeFormEntitySelect<DataType.UserAdmin>
                }
                accessor={userAccessor}
                helpText={t('forms:helpText.resendTempUsersPassword')}
              />
            </FormStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button type="submit" intent="primary">
                {t('features:admin.resendUsersTempPassword')}
              </Button>
              <Button type="button" variant="ghost" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
