import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyCommitmentsExplorerRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CommitmentsExplorer" */ '@/components/routes/analytics/data-explorer/CommitmentsExplorerRoute'
  ).then((module) => ({ default: module.CommitmentsExplorerRoute }))
);

export default (): NamedRoute => ({
  path: 'data-explorer',
  name: 'commitments.data-explorer',
  element: <LazyCommitmentsExplorerRoute />,
});
