import React, { useCallback } from 'react';

import {
  Heading,
  Text,
  Progress,
  Box,
  VStack,
  StackDivider,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { ServerCogIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  ButtonPanel,
  Confirmation,
  HeaderNavigationItem,
} from '@m3ter-com/ui-components';

import { Task } from '@/types/tasks';

import { cancelTask, selectTasks } from '@/store/tasks/tasks';

export const HeaderTasksButton: React.FC = () => {
  const { t } = useTranslation();
  const tasks = useSelector(selectTasks);
  const dispatch = useDispatch();

  const onTaskCancel = useCallback(
    (task: Task) => {
      dispatch(cancelTask(task));
    },
    [dispatch]
  );

  return (
    <HeaderNavigationItem>
      <ButtonPanel
        badge={tasks.length > 0 ? tasks.length : undefined}
        icon={<Icon boxSize={6} as={ServerCogIcon} />}
        title={t('common:runningTasks')}
        variant="header-nav"
      >
        <VStack
          spacing={2}
          alignItems="stretch"
          divider={<StackDivider />}
          as="ul"
          listStyleType="none"
        >
          {tasks.length === 0 && <Text>{t('common:noRunningTasks')}</Text>}
          {tasks.map((task) => (
            <Flex as="li" key={task.id} alignItems="center">
              <Box flex={1}>
                <Heading size="xs">{task.title}</Heading>
                {task.details && <Text fontSize="sm">{task.details}</Text>}
                {task.progress !== undefined && (
                  <Progress value={task.progress} mt={1} />
                )}
              </Box>
              {task.cancellable && (
                <Confirmation
                  trigger={
                    <Button size="xs" ml={2}>
                      {t('common:cancel')}
                    </Button>
                  }
                  onConfirm={() => {
                    onTaskCancel(task);
                  }}
                />
              )}
            </Flex>
          ))}
        </VStack>
      </ButtonPanel>
    </HeaderNavigationItem>
  );
};
