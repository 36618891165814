import {
  Id,
  PrincipalPermission,
  ServiceUserCredential,
  UserGroup,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { del, get, post, put } from '../client';

export interface UpdateEntityPermissionPolicyBody {
  principalId: Id;
}

interface UpdateEntityPermissionPolicyData {
  actionName: string;
  organizationId: Id;
  permissionPolicyId: Id;
  data?: Partial<UpdateEntityPermissionPolicyBody>;
}

// Similar to `ListResponse`, but `ServiceUserCredential` is not an `Entity`.
export interface ServiceUserCredentialsResponse {
  data: Array<ServiceUserCredential>;
}

export interface UpdateUserGroupsUsersBody {
  targetType: 'item';
  targetId: Id;
}

const emptyBody: Partial<UpdateEntityPermissionPolicyBody> = {};

export const getResourceTypes = () =>
  get({ path: '/resourcetypes' }) as Promise<Array<string>>;

export const updateEntityPermissionPolicy = ({
  actionName,
  organizationId,
  permissionPolicyId,
  data = emptyBody,
}: UpdateEntityPermissionPolicyData) =>
  post({
    body: data,
    path: getOrganizationPath(
      '/permissionpolicies/:permissionPolicyId/:actionName'
    ),
    pathParams: {
      organizationId,
      permissionPolicyId,
      actionName,
    },
  }) as Promise<PrincipalPermission>;

export const getServiceUserCredentials = (
  organizationId: Id,
  serviceUserId: Id
) =>
  get({
    path: getOrganizationPath('/serviceusers/:serviceUserId/credentials'),
    pathParams: { organizationId, serviceUserId },
  }) as Promise<ServiceUserCredentialsResponse>;

export const createServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id
) =>
  post({
    path: getOrganizationPath('/serviceusers/:serviceUserId/credentials'),
    pathParams: { organizationId, serviceUserId },
  }) as Promise<ServiceUserCredential>;

export const deleteServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id,
  apiKey: Id
) =>
  del({
    path: getOrganizationPath(
      '/serviceusers/:serviceUserId/credentials/:apiKey'
    ),
    pathParams: { organizationId, serviceUserId, apiKey },
  }) as Promise<ServiceUserCredential>;

export const deactivateServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id,
  apiKey: Id
) =>
  put({
    path: getOrganizationPath(
      '/serviceusers/:serviceUserId/credentials/:apiKey/inactivate'
    ),
    pathParams: { organizationId, serviceUserId, apiKey },
  }) as Promise<ServiceUserCredential>;

export const activateServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id,
  apiKey: Id
) =>
  put({
    path: getOrganizationPath(
      '/serviceusers/:serviceUserId/credentials/:apiKey/activate'
    ),
    pathParams: { organizationId, serviceUserId, apiKey },
  }) as Promise<ServiceUserCredential>;

export const addUserToUserGroup = (
  organizationId: Id,
  userGroupId: Id,
  userId: Id
) =>
  post({
    path: getOrganizationPath('/resourcegroups/user/:userGroupId/addresource'),
    pathParams: { organizationId, userGroupId },
    body: { targetType: 'item', targetId: userId },
  }) as Promise<UserGroup>;

export const removeUserFromUserGroup = (
  organizationId: Id,
  userGroupId: Id,
  userId: Id
) =>
  post({
    path: getOrganizationPath(
      '/resourcegroups/user/:userGroupId/removeresource'
    ),
    pathParams: { organizationId, userGroupId },
    body: { targetType: 'item', targetId: userId },
  }) as Promise<UserGroup>;
