import { extendTheme, ThemeConfig, ThemeOverride } from '@chakra-ui/react';

import colors from './foundations/colors';
import semanticTokens from './foundations/semantic-tokens';
import styles from './styles';

import Accordion from './components/accordion';
import AccordionCard from './components/accordion-card';
import AppLayout from './components/app-layout';
import Button from './components/button';
import Card from './components/card';
import ChangeIndicator from './components/change-indicator';
import Checklist, {
  checklistItem as ChecklistItem,
} from './components/checklist';
import SyntaxHighlighter from './components/syntax-highlighter';
import Combobox from './components/combobox';
import DataTable from './components/data-table';
import DatePicker from './components/date-picker';
import FileDropzone from './components/file-dropzone';
import FileInput from './components/file-input';
import Form from './components/form-control';
import Header from './components/header';
import HeaderActions from './components/header-actions';
import HeaderNavigation from './components/header-navigation';
import InteractiveTable from './components/interactive-table';
import Input from './components/input';
import KeyValue from './components/key-value';
import KeyValueList from './components/key-value-list';
import Link from './components/link';
import List from './components/list';
import Menu from './components/menu';
import Navigation from './components/navigation';
import NavigationPanel from './components/navigation-panel';
import Notification from './components/notification';
import NotificationsList from './components/notifications-list';
import Popover from './components/popover';
import Radio from './components/radio';
import RadioTabs from './components/radio-tabs';
import { radioTile as RadioTile } from './components/radio-tiles';
import StaticTable from './components/static-table';
import Stepper from './components/stepper';
import Steps, { step as Step } from './components/steps';
import TabbedCard from './components/tabbed-card';
import TabbedLinks, { tabLink as TabLink } from './components/tabbed-links';
import Table from './components/table';
import Tabs from './components/tabs';
import TagButton from './components/tag-button';
import Text from './components/text';
import Textarea from './components/textarea';
import Well from './components/well';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const overrides: ThemeOverride = {
  colors,
  components: {
    Accordion,
    AccordionCard,
    AppLayout,
    Button,
    Card,
    ChangeIndicator,
    Checklist,
    ChecklistItem,
    Combobox,
    DataTable,
    DatePicker,
    FileDropzone,
    FileInput,
    Form,
    Header,
    HeaderActions,
    HeaderNavigation,
    InteractiveTable,
    Input,
    KeyValue,
    KeyValueList,
    Link,
    List,
    Menu,
    Navigation,
    NavigationPanel,
    Notification,
    NotificationsList,
    Popover,
    Radio,
    RadioTabs,
    RadioTile,
    StaticTable,
    Stepper,
    Step,
    Steps,
    SyntaxHighlighter,
    TabbedCard,
    TabbedLinks,
    TabLink,
    Table,
    Tabs,
    TagButton,
    Text,
    Textarea,
    Well,
  },
  config,
  semanticTokens,
  styles,
};

export default extendTheme(overrides);
