import { useCallback, useState } from 'react';

import i18next from 'i18next';

import { AppError } from '@/types/errors';

import { extractError } from '@/util/error';

// Allows an onClick on an element to trigger a download after
// getting the download URL from an API data action.
const useDownloadUrl = (getDownloadUrlFn: () => Promise<string>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<undefined | AppError>();

  const download = useCallback(() => {
    setIsLoading(true);
    setDownloadError(undefined);

    getDownloadUrlFn()
      .then((downloadUrl) => {
        setIsLoading(false);
        const downloadWindow = window.open(downloadUrl);

        if (!downloadWindow) {
          setDownloadError({
            message: i18next.t('erros:downloads.downloadFailed'),
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDownloadError(extractError(error));
      });
  }, [getDownloadUrlFn]);

  return { download, isLoading, downloadError };
};

export default useDownloadUrl;
