import React from 'react';

import { Params } from 'react-router-dom';

import useNamedLink from '@/hooks/navigation/useNamedLink';
import {
  HeaderNavLink,
  HeaderNavLinkProps,
} from '@/components/common/navigation/HeaderNavLink';

export interface NamedHeaderNavLinkProps
  extends Omit<HeaderNavLinkProps, 'to'> {
  name: string;
  params?: Params;
  queryParams?: Record<string, any>;
}

export const NamedHeaderNavLink: React.FC<NamedHeaderNavLinkProps> = ({
  name,
  params,
  queryParams,
  ...props
}) => {
  const link = useNamedLink(name, params, queryParams);

  return <HeaderNavLink to={link} {...props} />;
};
