import React, { Suspense, useEffect } from 'react';

import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  AppLayout,
  AppLayoutHeader,
  AppLayoutMain,
  LoadingScreen,
} from '@m3ter-com/ui-components';

import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import {
  selectBootstrapFailureError,
  selectBootstrapFailureReason,
  selectOrgTimezone,
  BootstrapFailureReason,
  changeOrg,
  selectIsBootstrapping,
} from '@/store/app/bootstrap/bootstrap';
import useOrg from '@/hooks/data/crud/useOrg';
import { TimezoneContextProvider } from '@/hooks/util/useDateFormatter';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { ConsoleHeader } from '@/components/common/navigation/ConsoleHeader';

const BootstrapFailureContent: React.FC = () => {
  const { t } = useTranslation();
  const appBootstrapFailureError = useSelector(selectBootstrapFailureError);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason)!;

  return (
    <AppLayout>
      <AppLayoutHeader>
        <ConsoleHeader showNavigation={false} />
      </AppLayoutHeader>
      <AppLayoutMain>
        {appBootstrapFailureReason === BootstrapFailureReason.FatalError &&
          !!appBootstrapFailureError && (
            <ErrorAlert error={appBootstrapFailureError} />
          )}
        {appBootstrapFailureReason === BootstrapFailureReason.InvalidOrgId && (
          <ErrorAlert
            error={{ message: t('errors:organizations.invalidOrgId') }}
          />
        )}
        {appBootstrapFailureReason ===
          BootstrapFailureReason.NoOrgsAvailable && (
          <ErrorAlert
            error={{ message: t('errors:organizations.noAvailableOrgs') }}
          />
        )}
        {appBootstrapFailureReason ===
          BootstrapFailureReason.OrgDataLoadingFailure && (
          <ErrorAlert
            title={t('errors:organizations.noUserPermissions')}
            error={{
              message: t('errors:organizations.orgDataLoadingFailure', {
                error: appBootstrapFailureError?.message,
              }),
            }}
          />
        )}
      </AppLayoutMain>
    </AppLayout>
  );
};

const BoostrapSuccessContent: React.FC = () => {
  const orgTimezone = useSelector(selectOrgTimezone);

  return (
    <TimezoneContextProvider timeZone={orgTimezone}>
      <AppLayout>
        <AppLayoutHeader>
          <ConsoleHeader />
        </AppLayoutHeader>
        <Suspense
          fallback={
            <AppLayoutMain
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner size="xl" />
            </AppLayoutMain>
          }
        >
          <Outlet />
        </Suspense>
      </AppLayout>
    </TimezoneContextProvider>
  );
};

export const OrganizationRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { orgId: urlOrgId } = useParams();
  const { currentOrgId: stateOrgId } = useOrg();
  const isAppBootstrapping = useSelector(selectIsBootstrapping);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason);

  useEffect(() => {
    if (!isAppBootstrapping && urlOrgId && urlOrgId !== stateOrgId) {
      dispatch(changeOrg(urlOrgId));
    }
  }, [dispatch, isAppBootstrapping, stateOrgId, urlOrgId]);

  if (isAppBootstrapping) {
    setAuthRedirectPath(pathname + search);
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  if (!stateOrgId) {
    return null;
  }

  return appBootstrapFailureReason ? (
    <BootstrapFailureContent />
  ) : (
    <BoostrapSuccessContent />
  );
};
