import React from 'react';

import { Link } from 'react-router-dom';
import { Box, HStack, Image, StackDivider } from '@chakra-ui/react';
import { BlocksIcon, CircleAlertIcon, SettingsIcon } from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Header, HeaderNavigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useAuth from '@/hooks/auth/useAuth';
import { UserActions } from '@/components/common/user/UserActions';
import { NamedHeaderNavLink } from '@/components/common/navigation/NamedHeaderNavLink';
import { NamedHeaderNavIconLink } from '@/components/common/navigation/NamedHeaderNavIconLink';
import { OrgSwitcher } from '@/components/common/navigation/OrgSwitcher';

import { HeaderHelpMenu } from './HeaderHelpMenu';
import { HeaderWhatsNewButton } from './HeaderWhatsNewButton';
import { HeaderTasksButton } from './HeaderTasksButton';

export interface ConsoleHeaderProps {
  showNavigation?: boolean;
  showOrgSwitcher?: boolean;
}

export const ConsoleHeader: React.FC<ConsoleHeaderProps> = ({
  showNavigation = true,
  showOrgSwitcher = true,
}) => {
  const { t } = useTranslation();
  const { onSignOut, isM3terAdmin } = useAuth();

  return (
    <Header
      logo={
        <Link to="/">
          <Image src="/m3ter-logo-white.svg" alt="m3ter" width="90px" />
        </Link>
      }
      navigation={
        showNavigation && (
          <HeaderNavigation>
            <NamedHeaderNavLink activeOnlyIfExact name="dashboard">
              {t('common:dashboard')}
            </NamedHeaderNavLink>
            <NamedHeaderNavLink
              name={getCrudRouteName(DataType.Product, CrudRouteType.List)}
            >
              {t('common:products')}
            </NamedHeaderNavLink>
            <NamedHeaderNavLink name="usage">
              {t('common:metering')}
            </NamedHeaderNavLink>
            <NamedHeaderNavLink name="pricing-root">
              {t('common:pricing')}
            </NamedHeaderNavLink>
            <NamedHeaderNavLink name="accounts-root">
              {t('common:accounts')}
            </NamedHeaderNavLink>
            <NamedHeaderNavLink name="billing">
              {t('common:billing')}
            </NamedHeaderNavLink>
          </HeaderNavigation>
        )
      }
      actions={
        <HStack
          divider={<StackDivider borderColor="whiteAlpha.400" />}
          height="100%"
        >
          {showNavigation && (
            <HStack
              alignItems="stretch"
              gap={2}
              height="100%"
              justifyContent="center"
            >
              <NamedHeaderNavIconLink
                icon={<CircleAlertIcon />}
                label={t('common:alertsEvents')}
                name="events"
              />
              <HeaderHelpMenu />
              <HeaderWhatsNewButton />
              <HeaderTasksButton />
              <NamedHeaderNavIconLink
                icon={<BlocksIcon />}
                label={t('common:integrations')}
                name="integrations"
              />
              <NamedHeaderNavIconLink
                icon={<SettingsIcon />}
                label={t('common:settings')}
                name="settings"
              />
            </HStack>
          )}
          {showOrgSwitcher && <OrgSwitcher />}
          <UserActions
            onLogOutClick={onSignOut}
            isM3terAdminEnabled={isM3terAdmin}
          />
          {/* Userflow resource centre placeholder */}
          <Box w="50px" data-testid="userflow" />
        </HStack>
      }
    />
  );
};
