import React from 'react';

import {
  CalculatorIcon,
  DatabaseIcon,
  GaugeIcon,
  MessageSquareWarningIcon,
  SquareFunctionIcon,
  Tally5Icon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { getCrudRouteName, CrudRouteType } from '@/routes/crud';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';

export const MeteringNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Meter, CrudRouteType.List)}
        icon={<GaugeIcon />}
      >
        {t('common:meters')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.Aggregation, CrudRouteType.List)}
        icon={<CalculatorIcon />}
      >
        {t('common:aggregations')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(
          DataType.CompoundAggregation,
          CrudRouteType.List
        )}
        icon={<SquareFunctionIcon />}
      >
        {t('common:compoundAggregations')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Counters) && (
        <NamedNavLink
          name={getCrudRouteName(DataType.Counter, CrudRouteType.List)}
          icon={<Tally5Icon />}
        >
          {t('common:counters')}
        </NamedNavLink>
      )}
      <NamedNavLink name="ingest.events" icon={<MessageSquareWarningIcon />}>
        {t('features:ingest.ingestEvents')}
      </NamedNavLink>
      <NamedNavLink name="usage.data-explorer" icon={<DatabaseIcon />}>
        {t('features:dataExplorer.usage.title')}
      </NamedNavLink>
    </Navigation>
  );
};
