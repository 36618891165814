export enum AnalyticsJobType {
  BillingData = 'BILLS',
  BillingBasedRecurringRevenueReport = 'BILL_MRR',
  CommitmentsLineItemsData = 'COMMITMENT_LINEITEMS',
  MonthOnMonthReport = 'MOM',
  PrepaymentsStatusReport = 'COMMITMENTS_REPORT',
  RecurringRevenueReport = 'PLAN_MRR',
  TotalContractValueReport = 'TCV',
  UsageData = 'USAGE',
}

export enum AutoApprovePeriodUnit {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
}

// Extending the standard BillLineItemType with a few MRR-specific additions
export enum BillingBasedRecurringRevenueLineItemType {
  BalanceConsumed = 'BALANCE_CONSUMED',
  CommitmentConsumed = 'COMMITMENT_CONSUMED',
  CommitmentFee = 'COMMITMENT_FEE',
  CommitmentMrrAdjustment = 'COMMITMENT_MRR_ADJUSTMENT',
  CreditDeduction = 'CREDIT_DEDUCTION',
  CreditMemo = 'CREDIT_MEMO',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
  MinimumSpend = 'MINIMUM_SPEND',
  MinimumSpendMrrAdjustment = 'MINIMUM_SPEND_MRR_ADJUSTMENT',
  MinimumSpendRefund = 'MINIMUM_SPEND_REFUND',
  OverageSurcharge = 'OVERAGE_SURCHARGE',
  OverageUsage = 'OVERAGE_USAGE',
  StandingCharge = 'STANDING_CHARGE',
  Usage = 'USAGE',
  UsageCredit = 'USAGE_CREDIT',
}

export enum DateRange {
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
}

export enum ExternalMappingEntityType {
  Account = 'Account',
  AccountPlan = 'AccountPlan',
  AggregatedUsage = 'AggregatedUsage',
  Aggregation = 'Aggregation',
  Balance = 'Balance',
  Bill = 'Bill',
  BillItem = 'BillItem',
  BillLineItem = 'BillLineItem',
  BillLineItemPricingBand = 'BillLineItem__PricingBand',
  Commitment = 'Commitment',
  CompoundAggregation = 'CompoundAggregation',
  Contract = 'Contract',
  LedgerEntry = 'LedgerEntry',
  Meter = 'Meter',
  Organization = 'Organization',
  Plan = 'Plan',
  Pricing = 'Pricing',
  PricingBand = 'PricingBand',
  Product = 'Product',
}

export enum PermissionEffect {
  Allow = 'allow',
  Deny = 'deny',
}

export enum RecurringRevenueReportMonthConfig {
  Calendar = 'CALENDAR',
  Actual = 'ACTUAL',
  ThirtyDays = 'DAYS30',
  ThirtyOneDays = 'DAYS31',
}

// Used in the data explorer.
export enum UsageDataFrequency {
  Original = 'ORIGINAL',
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  WholePeriod = 'WHOLE_PERIOD',
}
