import { DateString, DateTimeISOString, Id } from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

interface ApproveBillsResponse {
  message: string;
}

interface DownloadResponse {
  downloadUrl: string;
}

interface DownloadBillsData {
  externalInvoiceDateStart: DateTimeISOString;
  externalInvoiceDateEnd: DateTimeISOString;
}

type StatementFormat = 'json' | 'csv';

export const approveBillsById = async (
  organizationId: Id,
  billIds: Array<Id>
) =>
  post({
    path: getOrganizationPath('/bills/approve'),
    pathParams: { organizationId },
    body: {
      billIds,
    },
  }) as Promise<ApproveBillsResponse>;

export const approveBillsByExternalInvoiceDate = async (
  organizationId: Id,
  externalInvoiceDateStart: DateString,
  externalInvoiceDateEnd: DateString
) =>
  post({
    path: getOrganizationPath('/bills/approve'),
    pathParams: { organizationId },
    queryParams: { externalInvoiceDateStart, externalInvoiceDateEnd },
  }) as Promise<ApproveBillsResponse>;

export const getBillStatementUrl = async (
  organizationId: Id,
  billId: Id,
  format: StatementFormat
) => {
  const response = (await get({
    path: getOrganizationPath('/bills/:billId/statement/:format'),
    pathParams: { organizationId, billId, format },
  })) as DownloadResponse;
  return response.downloadUrl;
};

export const getBillsDownloadUrl = async (
  organizationId: string,
  data: DownloadBillsData
) => {
  const response = (await post({
    path: getOrganizationPath('/bills/download/csv/url'),
    pathParams: { organizationId },
    body: data,
  })) as DownloadResponse;
  return response.downloadUrl;
};
