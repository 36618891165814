import React, { PropsWithChildren, ReactNode } from 'react';

import {
  chakra,
  Link,
  useMultiStyleConfig,
  forwardRef,
  LinkProps,
  Stack,
  Tooltip,
} from '@chakra-ui/react';

import { useNavigationPanelContext } from '../NavigationPanel';

interface NavigationIconProps {
  icon: ReactNode;
}

export const NavigationIcon = forwardRef<NavigationIconProps, 'span'>(
  ({ icon, ...rest }, ref) => {
    const styles = useMultiStyleConfig('Navigation', {});

    return (
      <chakra.span ref={ref} sx={styles.icon} {...rest}>
        {icon}
      </chakra.span>
    );
  }
);

export interface NavigationProps {}

export const Navigation: React.FC<PropsWithChildren<NavigationProps>> = ({
  children,
}) => (
  <Stack as="nav" spacing={2}>
    {children}
  </Stack>
);

export interface NavigationItemProps extends LinkProps {
  icon: ReactNode;
  isActive?: boolean;
}

export const NavigationItem = forwardRef<NavigationItemProps, 'a'>(
  ({ isActive, icon, children, ...rest }, ref) => {
    const { isCollapsed } = useNavigationPanelContext();
    const styles = useMultiStyleConfig('Navigation', { isActive });

    return (
      <Link ref={ref} {...rest} sx={styles.link} role="menuitem">
        {!isCollapsed ? (
          <React.Fragment>
            <NavigationIcon icon={icon} />
            {children}
          </React.Fragment>
        ) : (
          <Tooltip label={children} placement="right">
            <NavigationIcon icon={icon} />
          </Tooltip>
        )}
      </Link>
    );
  }
);
