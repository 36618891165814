import React from 'react';

import { DatabaseIcon, FileSpreadsheetIcon, UsersIcon } from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';

export const AccountsNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Account, CrudRouteType.List)}
        icon={<UsersIcon />}
      >
        {t('common:accounts')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Reports) && (
        <NamedNavLink name="accounts.reports" icon={<FileSpreadsheetIcon />}>
          {t('common:reports')}
        </NamedNavLink>
      )}
      <NamedNavLink name="commitments.data-explorer" icon={<DatabaseIcon />}>
        {t('features:dataExplorer.commitments.title')}
      </NamedNavLink>
    </Navigation>
  );
};
